
/* .fc-daygrid-event-harness.fc-daygrid-event-harness-abs {
    right: 0px !important;
} */
.content-pto-cal {
	.fc {
		.fc-toolbar.fc-header-toolbar {
			margin-bottom: 0;
			padding: 20px 20px 10px 10px;
			@media (max-width:767px) {
				padding-top: 0;
			}
		}
	}
	.fc-day-today {
		outline: solid 3px $violet;
		outline-offset: -3px;
		background: $gray-100 !important;
	}
}
.fc {
	.fc-daygrid-day-frame {
		height: 150px !important;
	}
}
.wrapper-pto-filter {
	margin: 10px 0;
	button.active {
		border-bottom: 4px solid $yellow !important;
		border-radius: 6px;
	}
	button {
		margin-bottom: 5px;
	}
	.btn-filter-status {
		border: 1px solid $gray-200 !important;
		cursor: pointer;
		padding: 10px 4px;
		border-radius: 5px;
		width: 150px !important;
		display: inline-block;
		font-size: 14px;
		font-weight: 500;
		margin-right: 5px;
		&:focus {
			outline: 0 !important;
		}
	}
}
.btn-show-all {
	color: $black;
}
.btn-time-off {
	background-color: $ltgreen;
	color: $white !important;
}
.btn-time-off-no-pay {
	background-color: $ltyellow;
	color: $black !important;
}
.btn-pay-no-time-off {
	background-color: $jellybean;
	color: $white !important;
}
.btn-denied {
	background-color: $gray-500;
	color: $white !important;
}
.btn-pending {
	background-color: $ltred;
	color: $white !important;
}
.pto-req-form {
	.radio-button {
		vertical-align: middle;
		margin-left: 5px;
	}
}
.radio-label {
	line-height: 30px;
	padding: 4px 10px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}
.radio-label-1 {
	background-color: $ltgreen;
	color: $white !important;
}
.radio-label-2 {
	background-color: $ltyellow;
	color: $black !important;
}
.radio-label-3 {
	background-color: $jellybean;
	color: $white !important;
}
.denied-event {
	.fc-event-title {
		text-decoration: line-through !important;
	}
}
.time-off-event {
	.fc-event-title {
		color: $black !important;
	}
}
.date-icon-label {
	cursor: pointer;
}
.date-range-label {
	cursor: pointer;
	vertical-align: text-bottom;
	margin-left: 5px;
}
#date-popup {
	>div {
		position: relative !important;
	}
}
.daterangepicker {
	z-index: 9999 !important;
}
.btn-pto-close {
	background-color: transparent !important;
}
.date-range-selected {
	color: $black;
	margin-left: 15px;
	margin-top: 10px;
	vertical-align: text-bottom;
	cursor: default;
}
.legend {
	margin: calc(-1rem - 11px) auto 0.5rem -5px;
	background: $white;
	padding: 0px 5px;
	display: inline-block;
}
.rdrDefinedRangesWrapper {
	display: none !important;
}
.wrapper-daterange-picker {
	padding: 5px;
	border: 1px solid $gray-200;
	border-radius: 5px;
}
.add-event-icon {
	display: none;
	cursor: pointer;
		width: 30px;
		position: absolute;
		bottom: 20px;
		right: 10px;
		z-index: 9999;
	img {
		width: 100%;
	}
}
.fc-daygrid-day {
	&:hover {
		.add-event-icon {
			display: block;
		}
	}
}
.time-off-pay-0 {
	.fc-event-title {
		color: $black !important;
	}
}
.pt-pto-popup {
	border: none;
	.modal-header {
		padding: 12px 15px;
	}
	.modal-title {
		font-size: 17px;
	}
	.form-group {
		margin-bottom: 8px;
		label {
			font-size: 14px;
		}
	}
	.radio-label {
		line-height: 25px;
		font-size: 15px;
		margin-bottom: 10px;
	}
	.rdrCalendarWrapper {
		font-size: 10px;
	}
	.form-control {
		padding: 4px 12px;
		font-size: 14px;
	}
	.css-yk16xz-control {
		min-height: 35px !important;
	}
}
.pt-pto-popup-mb0 {
	margin-bottom: 0px !important;
}
.wrapper-comment {
	.card-body {
		height: 120px;
		overflow-y: auto;
		padding: 10px !important;
	}
	.comment-sep {
		border-bottom: 1px solid $gray-200;
		margin-bottom: 5px;
	}
}
.rdrMonths {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.rdrDateRangePickerWrapper {
	max-width: 100%;
	width: 100%;
}
.rdrDateRangeWrapper {
	max-width: 100%;
	width: 100%;
}
.tbl-pto-statement-report .rowTotal {
	font-weight: bold;
	font-size: 15px;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	margin-bottom: 10px;
}
.tbl-pto-statement-report .table td, 
.tbl-pto-statement-report .table th {
	border: none;
}
@media only screen and (max-width: 990px) {
	.btn-group.flex-column.flex-sm-row.flex-lg-column {
		>label {
			// font-size: 15px !important;
			margin-right: 8px;
		}
	}
}
// @media (max-width: 1198px) {
// 	.content-topspace-margin {
// 		margin-top: 110px;
// 	}
// }
@media (max-width: 768px) {
	.fc-event-title {
		white-space: nowrap !important;
		overflow: hidden !important;
		font-size: 11px !important;
	}
	.fc-more-link {
		font-size: 11px;
		white-space: nowrap !important;
		overflow: hidden !important;
		display: flex;
	}
}
@media (max-width: 400px) {
	
	.fc-daygrid-event-dot {
		display: none;
	}
}
