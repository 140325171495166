
/* desktop
-------------------------------------------------------------------------------------------*/
.width250 {
	min-width: 250px;
}
.sidebar-desktop {
	position: relative;
	z-index: 9999;
}
.menu-image {
	width: 20px;
	margin-right: 7px;
}
.menu-icon {
	margin-right: .5em;
	color: $gray-600;
	font-size: 1.2em;
}
#top-menu {
	margin-bottom: 20px;
	list-style: none;
	z-index: 999;
	margin-bottom: 0px;
	overflow: auto;
	height: calc(100vh - 126px);
	a {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 700;
	}
	>li {
		&:hover {
			>ul {
				display: block;
				list-style: none;
			}
		}
		>ul {
			>li {
				>a {
					text-align: left;
				}
				>ul {
					>li {
						>a {
							text-align: left;
						}
					}
					display: none;
				}
				a {
					&:hover {
						color: $dkblue;
					}
				}
				position: relative;
			}
		}
	}
	li {
		ul {
			z-index: 9999;
			list-style: none;
			padding: 0 1rem 0 2.5rem;
			li {
				&:hover {
					ul {
						display: block;
						left: 100%;
						width: 250px;
						top: 0;
					}
				}
			}
		}
	}
	>li {
		>a {
			color: $gray-600;
			padding: 0.5rem 0.5rem 0.5rem 1.5rem;
		}
		>ul {
			>li {
				>a {
					color: $gray-600;
					font-weight: normal;
					padding: 0.5rem 0.5rem 0.5rem 1.5rem;
					&:hover {
						background-color: $gray-300;
						border-radius: 25px;
					}
				}
			}
		}
	}
}

.welcome-user {
	color: $gray-600;
	display: inline-flex;
	position: relative;
}
.hdusername {
	a.dropdown-toggle {
		display: flex;
		align-items: center;
		color: $gray-600;
		font-size: 13px;
		&:after {
			// content: "\f107";
			// font-family: $font-awesome;
			// font-weight: 600;
			// color: $gray-400;
			// height: 0px;
			// overflow: hidden;
			display: none;
		}
	}
	.dropdown-usermenu{
		left: auto !important;
		right: 6px !important;
		margin-top: 6px !important;

		> li{
			> a{
				padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;

				color: $gray-600 !important;
				font-size: 14px;

			}
		}
	}
}

.pt-msg-noty {
	max-height: 350px;
    padding: 15px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.pt-msg-noty table td {
	text-align: left;
	.pt-msg-noty-txt {
		margin: 10px 0;
		p {
			color: #373737;
			margin-bottom: 0;
			&:first-child {
				color: #9f9f9f;
			}
		}
	}
}

a.dropdown-toggle.msg-notification {
	color: #58555E;
	cursor: pointer;
	>img {
		width: 22px;
		height: auto;
		margin-bottom: 1px;
	}
}
.dropdown-menu{
	&.float-right {
		right: 0;
		left: auto !important;
	}
	&.float-left {
		left: 0;
		right: auto !important;
	}
}

.msg-notification {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 10px;
	&:after {
		content: none !important;
	}
}
.badge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 7px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: $white;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: $gray-500;
	border-radius: 10px;
}
.ntfication-count {
	border-radius: 3px;
	padding: 4px 6px;
}
.header-site-logo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: .5rem 1.5rem 0rem 1.5rem;
  height: 50px;
}
.brand-custom-logo {
  width: 140px !important;
}
.md_home-btn {
	display: inline-block;
	width: 36px;
	height: 36px;
	overflow: hidden;
	border-radius: 50px;
	img {
		width: 100%;
		height: 100%;
	}
}
.md_home-site-btn img {
  display: inline-block;
  width: 60px;
}
.company-name {
	font-weight: 700;
	font-size: 14px;
}
.page-title {
	font-weight: 700;
	font-size: 14px;
}

.setup-menu {
	font-size: 16px;
	line-height: inherit;
	text-transform: capitalize;
}
.mCS_img_loaded {
	width: 32px;
	height: 32px;
	margin-right: 5px;
	border-radius: 50%;
}
.submenu {
	max-height: 280px;
	min-width: 230px;
	overflow-y: auto;
	overflow-x: hidden;
}
ul.dropdown-menu {
	li.submenu-title {
		// border-bottom: 2px solid $gray-200 !important;
	}
}
.header-section {
	position: relative;
	z-index: 99;
	justify-content: space-between;
	align-items: center;
	padding-top: 10px;
}
.hdusername {
	background-color: $gray-100 !important;
	box-shadow: 0 0px 0px 1px $gray-100;
	border-radius: 3px;
	padding: 0.4rem 1.5rem 0.4rem 8px;
	width: 100%;
	.welcome {
		margin-right: 4px;

	}
	span.fa-angle-down {

		color: $black;


	}
}
.user-box-cmp{
	position: relative;
	z-index: 99999;
}
.nav-inner{
	background-color: #fff;
	z-index: 5;
	height: 100%;
	box-shadow: -1px 0 0 rgba(22, 8, 43, 0.1);
	position: relative;
  .nav-search{
    padding: 10px;
    border-bottom: 1px solid #ccc;
    .btn-sidebar-group button {
      font-size: 20px !important;
      margin-top: 5px;
      margin-right: 5px;
      color: #2a3f54;
      border: 1px solid #888484;
      border-radius: 2px;
    }
    .btn-clear {
      padding: 1px 8px;
    }
    .sidebar-text-count {
      margin-top: 5px;
      font-weight: 500;
      color: rgb(165, 42, 42);
    }
  }
  .sidebar-help-title {
    padding-top: 10px;
  	text-align: center;
    .sidebar-title {
      color: #403b3b;
      font-size: 20px;
      font-weight: 500;
    }

  }
  .nav-content {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    overflow: auto;
    max-height: calc(100vh - 94px);
    font-family: "Roboto", sans-serif;
    .highlight {
      background: #ffff00;
    }
    .current-highlight {
      background: #ffa705;
    }
  }
  .nav-content .md-page-name{
    margin-bottom: 15px;
  }
  .nav-content p{
    margin-top: 0;
  }
  .nav-content ul{
    list-style: inside;
  }
  .nav-content ul li{
    margin-bottom: 10px;
  }
  .search-help-bar > div{
    float: none !important;
    width: auto !important;
    margin: 0 !important;
    font-family: "Roboto", sans-serif !important;
  }
  .search-help-bar > div span{
    font-family: "Roboto", sans-serif !important;
  }
}

@media (min-width: 992px) {
	.collapse-btn{
		position: absolute;
		top: calc(50% - 24px);
		width: 23px;
		height: 48px;
		cursor: pointer;
		border: none;
		background: $white 7px center/7px 10px no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		&:after {
			content: "";
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			display: inline-block;
			opacity: 0.6;
		}
	}
	.left-collapse-btn{
		left: 100%;
		box-shadow: 0 1px 2px rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
		border-radius: 0 8px 8px 0;
		&:after {
			border-right: 5px solid $black;
		}
	}
	.right-collapse-btn{
		right: 100%;
		box-shadow: 0 -1px 2px rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
		border-radius: 8px 0 0 8px;
		&:after {
			border-left: 5px solid $black;
		}
	}
	.main-sidebar{
		position: fixed;
		width: 250px;
		top: 0;
		bottom: 0;
		transition: all 0.4s ease;
	}
	.left-main-sidebar{
		left: 0;
	}
	.right-main-sidebar{
		right: 0;
	}
	.main-content {
		margin-left: 260px;
		margin-right: 260px;
		width: calc(100% - 520px);
		transition: all 0.4s ease;
	}
.collpase-menu{
	.left-main-sidebar{
		left: -250px;
	}
	.main-content {
		margin-left: 10px;
		width: calc(100% - 270px);
	}
	.left-collapse-btn:after {
		transform: rotate(180deg);
	}
	&.right-collpase-menu{
		.main-content {
			margin-left: 10px;
			margin-right: 10px;
			width: calc(100% - 20px);
		}
	}
}
.right-collpase-menu{
	.right-main-sidebar{
		right: -250px;
	}
	.main-content {
		margin-right: 10px;
		width: calc(100% - 270px);
	}
	.right-collapse-btn:after {
		transform: rotate(-180deg);
	}
}

	.top-filter {
		max-width: 100% !important;
		right: 0;
		left: 0 !important;
	}
	header.fixed-top {
		position: relative;
		right: auto;
		bottom: 0;
		background-color: $white;
		box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
		left: 0;
		transition: .4s;
		z-index: 5;
		height: 100%;
	}
}
@media screen and (min-width: 992px){
	.user-box-cmp {
		padding-bottom: 0px;
		display: block;
		margin: 10px 15px 15px;
	}
	.hdusername {
		span.fa-angle-down {
			position: absolute;
			font-size: 1.5em;
			right: 10px;
		}
	}
}

@media (max-width: 991px){
	a.dropdown-toggle.msg-notification{
		> img{
			width: 25px !important;
    		//margin-top: 10px;
		}
	}
	.hdusername{
		box-shadow: none;
	}
}

