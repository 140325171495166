$black: #000;
$white: #fff;

//gray
$gray-50:#fcfcfc;
$gray-60:#f0f0f0;
$gray-100:#e4e4e4;
$gray-200:#ccc;
$gray-300:#d1d5db;
$gray-400:#9ca3af;
$gray-500:#6c757d;
$gray-600:#52525b;
$gray-700:#373737;
$gray-800:#27272a;
$gray-900:#18181b;

//blue

$ltblue: #a1d1e8;
$blue:#007bff;
// $jellybean:#3092c0;
$jellybean:#32849b;
$tblbggreen:#32849b;
$tblbgblue:#3092c0;
$navyblue: #001f60;
$dkblue:#2a3f54;
$violet:#719df8;

//red
$ltred:#C35046;
$red: #FC0000;
//$darkred: #880d0d;
$darkred: #c9302c;

//green
$ltgreen: #00AF50;
$green: #34b363;

//yellow
$ltyellow: #FED966;
$ltsyellow: #f1f1da;
$yellow:#ffc000;

$primary: $jellybean;

// Font awesome family
$font-awesome: "FontAwesome";

$box-shadow: 2px 2px 2px 0px $gray-200;
$box-shadow-custom-btn:4px 5px 5px 0px #aaaaaa;

// button
$btn-space-x: 12px;
$btn-space-y: 4px;
$btn-font-size:14px;
$btn-line-height:24px;
$btn-border-radius:14px;
$btn-border-width:1px;
$btn-border-style:solid;

$btn-box-shadow: none;

// Button color theme

$btn-primary-color: $white;
$btn-primary-bg:  $primary;
$btn-primary-border-color: $btn-primary-bg;

//$btn-primary-color-hover: $white;
$btn-primary-bg-hover: rgba($btn-primary-bg,0.8);
$btn-primary-border-color-hover: $btn-primary-bg-hover;

$btn-primary-bg-disabled: $ltblue;
$btn-primary-border-color-disabled: $btn-primary-bg-disabled;

// Secondary
$btn-secondary-bg:  $gray-500;
$btn-secondary-border-color: $btn-secondary-bg;

$btn-secondary-bg-hover: rgba($btn-secondary-bg,0.8);
$btn-secondary-border-color-hover: $btn-secondary-bg-hover;

$btn-secondary-bg-disabled: $gray-200;
$btn-secondary-border-color-disabled: $btn-secondary-bg-disabled;

// Danger
$btn-danger-bg:  $darkred;
$btn-danger-border-color: $btn-danger-bg;

$btn-danger-bg-hover: rgba($btn-danger-bg,0.8);
$btn-danger-border-color-hover: $btn-danger-bg-hover;

$btn-danger-bg-disabled: $ltred;
$btn-danger-border-color-disabled: $btn-danger-bg-disabled;

// Danger outline
$btn-o-danger-color:  $darkred;
$btn-o-danger-border-color: $btn-o-danger-color;

$btn-o-danger-color-hover:$white;
$btn-o-danger-bg-hover:$btn-o-danger-color;
$btn-o-danger-border-color-hover: $btn-o-danger-bg-hover;

$btn-o-danger-color-disabled: $ltred;
$btn-o-danger-border-color-disabled: $btn-o-danger-color-disabled;
