@charset "utf-8";
/* CSS Document */
// @import url('http://qa.protrackersystem.com/panel/css/fonts/font-awesome.css');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

html {
	-webkit-text-size-adjust: none;
	height: 100%;
	font-size: 10px;
}
body {
	overflow-x: hidden;
	background: $gray-60;
	font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
	min-height: calc(100vh - 1px);
	position: relative;
	font-size: 14px;
	line-height: 1.42857143;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
form,
input,
textarea,
select {
	margin: 0;
	padding: 0;
}
input {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

img {
	border: 0;
}
a {
	text-decoration: none;
	outline: none !important;
	outline-color: transparent !important;
	&:link,
	&:hover {
		text-decoration: none;
		transition: all 0.3s ease-in;
	}
}
button {
	&:focus {
		outline: none;
	}
}
.mtp0 {
	margin-top: 0px;
}
.mtp5 {
	margin-top: 5px;
	margin-top: 5px !important;
}
.mtp10 {
	margin-top: 10px;
}
.mtp15 {
	margin-top: 15px;
}
.mtp20 {
	margin-top: 20px;
}
.mtp25 {
	margin-top: 25px;
}

.mtp30 {
	margin-top: 30px;
}
.mtp35 {
	margin-top: 35px;
}
.mtp40 {
	margin-top: 40px;
}
.mtp45 {
	margin-top: 45px;
}
.mbp0 {
	margin-bottom: 0px;
}
.mbp5 {
	margin-bottom: 5px;
}
.mbp10 {
	margin-bottom: 10px;
}
.mbp15 {
	margin-bottom: 15px;
}
.mbp20 {
	margin-bottom: 20px;
}
.mbp25 {
	margin-bottom: 25px;
}
.mbp30 {
	margin-bottom: 30px;
}
.mbp35 {
	margin-bottom: 35px;
}
.mbp40 {
	margin-bottom: 40px;
}
.mbp45 {
	margin-bottom: 45px;
}
.ptp0 {
	padding-top: 0px;
}
.ptp5 {
	padding-top: 5px;
}
.ptp10 {
	padding-top: 10px;
}
.ptp15 {
	padding-top: 15px;
}
.ptp20 {
	padding-top: 20px;
}
.ptp25 {
	padding-top: 25px;
}
.ptp30 {
	padding-top: 30px;
}
.ptp35 {
	padding-top: 35px;
}
.ptp40 {
	padding-top: 40px;
}
.ptp45 {
	padding-top: 45px;
}
.pbp0 {
	padding-bottom: 0px;
}
.pbp5 {
	padding-bottom: 5px;
}
.pbp10 {
	padding-bottom: 10px;
}
.pbp15 {
	padding-bottom: 15px;
}
.pbp20 {
	padding-bottom: 20px;
}
.pbp25 {
	padding-bottom: 25px;
}
.pbp30 {
	padding-bottom: 30px;
}
.pbp35 {
	padding-bottom: 35px;
}
.pbp40 {
	padding-bottom: 40px;
}
.pbp45 {
	padding-bottom: 45px;
}
.pb300 {
	padding-bottom: 300px;
}
.pb400 {
	padding-bottom: 400px;
}
.font-size8 {
	font-size: 8px;
}
.font-size10 {
	font-size: 10px;
}
.font-size12 {
	font-size: 12px;
}
.font-size14 {
	font-size: 14px !important;
}
.font-size15 {
	font-size: 15px;
}
.font-size16 {
	font-size: 16px;
}
.font-size18 {
	font-size: 18px;
}
.font-size20 {
	font-size: 20px;
}
.font-size22 {
	font-size: 21px;
}
.font-size24 {
	font-size: 22px;
}
.font-size26 {
	font-size: 23px;
}
.font-size28 {
	font-size: 24px;
}
.font-size30 {
	font-size: 25px;
}
.font-size32 {
	font-size: 26px;
}
.font-size34 {
	font-size: 27px;
}
.font-size36 {
	font-size: 28px;
}
.font-size38 {
	font-size: 29px;
}
.font-size40 {
	font-size: 30px;
}
.font-w300 {
	font-weight: 300;
}
.font-w400 {
	font-weight: 400;
}
.font-w500 {
	font-weight: 500 !important;
}
.font-w600 {
	font-weight: 600;
}
.font-w700 {
	font-weight: 700;
}
.font-w900 {
	font-weight: 900;
}
.gutter-20 {
	&.row {
		margin-right: -10px;
		margin-left: -10px;
	}
	> [class^="col-"] {
		padding-right: 10px;
		padding-left: 10px;
	}
	> [class^="col-"] {
		padding-right: 10px;
		padding-left: 10px;
	}
}

.gutter-5 {
	&.row {
		margin-right: -4px;
		margin-left: -4px;
	}
	> [class^="col-"] {
		padding-right: 4px;
		padding-left: 4px;
	}
	> [class^="col-"] {
		padding-right: 4px;
		padding-left: 4px;
	}
}

.gutter-10 {
	&.row {
		margin-right: -5px;
		margin-left: -5px;
	}
	> [class^="col-"] {
		padding-right: 5px;
		padding-left: 5px;
	}
	> [class^="col-"] {
		padding-right: 5px;
		padding-left: 5px;
	}
}

.gutter-0 {
	&.row {
		margin-right: 0;
		margin-left: 0;
	}
	> [class^="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
	> [class^="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}
.div-hide {
	display: none;
}
.add-new {
	display: none;
}
.mandatory {
	color: $red;
}
.vh-main {
	display: table;
	text-align: center;
	vertical-align: middle;
	width: 100%;
	height: 100vh;
}
.vh-in {
	display: table-cell;
	vertical-align: middle;
}
.text-black {
	color: $black;
}
.text-blue {
	color: $navyblue;
}
.text-gray {
	color: $gray-600;
}
.text-lightgray {
	color: $gray-400;
}
.text-orange {
	color: $yellow;
}
.whitebg {
	background: $white;
}
.boxshadow {
	// background: $white;
	// border-radius: 4px;
	// border: solid 1px $gray-200;
	// box-shadow: $box-shadow;
}
.detailsbtn.showdetails {
	background: $gray-300;
	color: $white;
	border-color: $gray-300;
	box-shadow: none;
	&:hover {
		box-shadow: none;
	}
	&:focus {
		box-shadow: none;
	}
}
.detailsbtn {
	&:hover {
		box-shadow: none;
	}
	&:focus {
		box-shadow: none;
	}
}
.roundbtn {
	display: inline-block;
	background: $gray-800;
	color: $white;
	width: 35px;
	height: 35px;
	padding-top: 8px;
	text-align: center;
	border-radius: 50%;
	line-height: 16px;
	line-height: 20px;
	&:hover {
		background: $blue;
		color: $white !important;
	}
}
.roundbtn.active {
	background: $blue;
	color: $white !important;
}
#sidebar {
	width: 245px;
	position: fixed;
	top: 0;
	left: -250px;
	height: 100vh;
	z-index: 99999;
	background-color: $white;
	color: $gray-600;
	transition: all 0.3s;
	@media (min-width: 992px) {
		display: none !important;
	}
	#dismiss {
		display: none;
	}
	&.active {
		box-shadow: 0px 1px 0px 100rem rgba($black, 0.55);
		left: 0;
		#dismiss {
			display: inline-block;
		}
	}
}
#menu-scroll {
	overflow-y: auto !important;
	height: calc(100vh - 138px);
}

#dismiss {
	position: fixed;
	top: 9px;
	right: 12px;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	color: $white;
	font-size: 20px;
}
.menuoverlay {
	display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba($black, 0.7);
	z-index: 9999;
	opacity: 0;
	transition: all 0.5s ease-in-out;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.menuoverlay.active {
	display: block;
	opacity: 1;
}
.btn-link {
	border-radius: 50%;
	padding: 5px 9px 6px;
	&:hover {
		border-color: $navyblue;
	}
}
.profilebox {
	background: $yellow;
	padding-bottom: 10px;
	padding-top: 10px;
}
.graytext {
	color: $gray-400;
}
.profilephoto {
	border-radius: 50%;
	width: 60px;
	height: 60px;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
}
.pro-edit {
	border: solid 1px $black;
	position: absolute;
	background: $white;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	right: 80px;
	z-index: 9;
	padding-top: 3px;
	color: $darkred !important;
	font-size: 12px;
}
.blackbtn {
	border-radius: 15px;
	background: $black;
	color: $white !important;
	padding: 4px 15px;
	display: inline-block;
}

.logobg {
	margin: .5rem 0;
	display: inline-block;
	img {
		width: 104px;
	}
}
.hd-icon {
	position: relative;
	display: inline-block;
	color: $yellow !important;
	font-size: 20px;
	padding: 15px 12px 9px;
}
.usericon {
	width: 45px;
	height: 45px;
	box-sizing: border-box;
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
	border: solid 1px $gray-200;
	overflow: hidden;
	margin-bottom: -9px;
	top: 8px;
	position: relative;
}
.count {
	position: absolute;
	background: $ltred;
	padding: 2px 3px 1px;
	color: $white;
	font-size: 11px;
	top: 7px;
	right: 0px;
	border-radius: 50%;
}
.hddropdown {
	.dropdown-item {
		padding: 4px 15px;
		font-size: 14px;
	}
}
.hdusername {
	.name {
		max-width: 100px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: inline-block;
		margin-right: 6px;
	}
}
.hd-dropdown {
	min-width: 136px;
}
.titlerow {
	// -webkit-box-shadow: 0px 2px 2px 0px rgba(202, 202, 202, 0.75);
	// -moz-box-shadow: 0px 2px 2px 0px rgba(202, 202, 202, 0.75);
	// box-shadow: 0px 2px 2px 0px rgba(202, 202, 202, 0.75);
	// background: $white;
	z-index: 1;
}
.title-icon {
	margin-left: auto;
	a {
		display: flex;
		padding: 4px 5px;
		height: 30px;
		min-width: 30px;
		justify-content: center;
		align-items: center;
		+ {
			a {
				border-left: solid 1px $gray-100;
			}
		}
	}
}
.iconbtn.active {
	color: $gray-300;
}
.content-topspace {
	margin-bottom: 30px;
}
.content-topspace-sm {
	margin-top: 52px;
	margin-bottom: 30px;
}
.whitebox {
	background: $white;
	display: inline-block;
	color: $black;
	border-radius: 4px;
	border: solid 1px $gray-200;
	min-width: calc(33.333333% - 10px);
	margin-left: 5px;
	margin-right: 5px;
	box-shadow: $box-shadow;
	margin-top: 10px;
	padding-bottom: 10px;
}
.searchbox {
	min-width: 70%;
	.input-group-text {
		background: $white;
		border-left: 0px;
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		color: $gray-400;
	}
	.form-control {
		background: $white;
		border-right: 0px;
	}
	.input-group-prepend {
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		color: $gray-400;
	}
}
.selectwidth {
	min-width: 120px;
}
.table-style {
	th {
		background: $tblbggreen;
		color: $white;
		border-bottom: 0px;
		font-weight: 500;
		padding: 10px;
		font-size: 14px;
	}
	td {
		padding: 7px 10px;
		font-size: 14px;
		color: $gray-700;
		font-weight: 400;
	}
}
.text-nowrap-xl {
	white-space: nowrap !important;
}
.gridicon {
	padding: 0px 4px;
	display: inline-block;
}
// .paginationstyle {
// 	.page-item {
// 		margin-left: 3px;
// 		padding-right: 3px;
// 		.page-link {
// 			background: $gray-200;
// 			color: $black;
// 			border-radius: 4px;
// 		}
// 		.page-link.number {
// 			background: #7fd6c4;
// 			color: $black;
// 		}
// 	}
// 	.page-item.disabled {
// 		pointer-events: none;
// 		opacity: 0.6;
// 	}
// }
.subarrow {
	position: absolute;
	right: 0;
	bottom: 0;
}
.subtable {
	.nospace {
		padding: 0px;
	}
	border-top: 0px;
	border-bottom: 0px;
	background: $gray-50;
	.inpt-box {
		height: 28px !important;
	}
	.table-style {
		td {
			padding: 5px 10px !important;
		}
		.bt-order-action-icon {
			button {
				padding-right: 30px;
				font-size: 16px !important;
			}
		}
	}
}
.subtabel {
	margin: 8px;
	table {
		background: $white;
		th {
			background: $gray-300;
			color: $black;
		}
	}
}
.subtablebuyout {
	table {
		th {
			background: $gray-300;
			&.receivedTbl {
				background: $gray-300;
			}
		}
	}
}

.arrowbtn {
	.openarrow {
		display: none;
	}
	.closearrow {
		display: inline-block;
	}
	&.active {
		.openarrow {
			display: inline-block;
		}
		.closearrow {
			display: none;
		}
	}
}
footer {
	background: $white;
	overflow: hidden;
	padding: 21px 11px;
	color: $gray-600;
	text-align: center;
	font-size: 13px;
	border-top: solid 1px $gray-200;
	margin-top: 25px;
	//margin-bottom: 50px;
	@media (min-width: 992px) {
		margin-left: -9px;
	}
	// margin-left: -20px;
	// margin-right: -20px;
}
.input-text {
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
	border-bottom-style: hidden;
	background-color: $white;
}
.no-outline {
	&:focus {
		outline: none;
	}
}
.hr-line {
	color: $darkred;
}
.float-right {
	float: right;
}
.btn-advanced-search {
	border: 2px solid $gray-400;
	background-color: $gray-200;
	color: $gray-400;
}
.search-container {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			~ {
				.checkmark {
					background-color: $jellybean !important;
					border-color: $jellybean !important;
					&:after {
						display: block;
					}
				}
			}
		}
	}
	&:hover {
		input {
			~ {
				.checkmark {
					background-color: $gray-200;
				}
			}
		}
	}
	.checkmark {
		&:after {
			left: 7px;
			top: 4px;
			width: 5px;
			height: 10px;
			border: solid white;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 23px;
	width: 23px;
	background-color: $gray-200;
	border: 1px solid $gray-500;
	color: $gray-500;
	border-radius: 3px;
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: $white;
	&:after {
		content: "";
		position: absolute;
		display: none;
		content: "";
		position: absolute;
		display: none;
	}
}
.radio-mark {
	margin-left: 10px;
	margin-right: 2px;
}
.search-text-color {
	color: $gray-800;
	font-weight: bold;
}
hr.hr {
	border-top: 1px solid $gray-200;
	// margin-right: -16px;
	// margin-left: -16px;
}
#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background: rgba($black, 0.1);
}
.loader {
	border: 5px solid $gray-100;
	border-radius: 50%;
	border-top: 5px solid blue;
	border-right: 5px solid green;
	border-bottom: 5px solid red;
	border-left: 5px solid pink;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

.pointer {
	cursor: pointer;
	div {
		cursor: pointer;
	}
	input {
		cursor: pointer;
	}
}
.md_calendar {
	display: inline-block;
	position: relative;
	cursor: pointer;
	top: 3px;
}
.cal {
	font-size: 1.5em;
	padding: 1px 6px 9px 6px;
}
.tbl-received-report {
	.dx-g-bs4-table {
		.dx-g-bs4-header-cell-content {
			display: block !important;
			text-align: center !important;
		}
		border-collapse: collapse !important;
	}
	.dx-g-bs4-toolbar {
		min-height: 0 !important;
	}
	.text-nowrap {
		white-space: normal !important;
	}
	.dx-g-bs4-table-sticky {
		z-index: 0 !important;
	}
	table {
		.dx-g-bs4-cursor-pointer {
			display: none;
		}
		.dx-g-bs4-group-cell {
			text-align: left !important;
		}
		.dx-g-bs4-table-summary-item {
			font-size: 16px;
			font-weight: bold;
		}
		tr {
			td {
				&:nth-child(6) {
					text-align: left;
				}
			}
		}
	}
}
.tbl-field-reporter {
	tr:last-child td {
		font-size: 16px !important;
		font-weight: bold !important;
	}
	tr:last-child {
		td:nth-child(2) > .link-type {
			display: none;
		}
	}
	// td:nth-child(8),
	// td:nth-child(9),
	// td:nth-child(10),
	// td:nth-child(11),
	// td:nth-child(13),
	// td:nth-child(14),
	// td:nth-child(15),
	// td:nth-child(16),
	// td:nth-child(17) {
	//   cursor: pointer;
	// }
}
.alert-warning-dailog {
	font-family: Arial, Helvetica, sans-serif;
	width: 400px;
	padding: 20px;
	text-align: left;
	background: $white;
	border-radius: 4px;
	box-shadow: 0 20px 75px rgba($black, 0.13);
	color: $gray-800;
	.dailog-title {
		margin-top: 0;
		font-size: 22px;
		margin-bottom: 20px;
		font-weight: 700;
		color: $darkred;
	}
}
body.react-confirm-alert-body-element {
	overflow: hidden;
}
.react-confirm-alert-blur {
	filter: url(#gaussian-blur);
	filter: blur(2px);
	-webkit-filter: blur(2px);
}
.react-confirm-alert-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.5);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	opacity: 0;
	-webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	z-index: 9999;
}
.react-confirm-alert-body {
	font-family: Arial, Helvetica, sans-serif;
	width: 400px;
	padding: 20px;
	text-align: left;
	background: $white;
	border-radius: 4px;
	box-shadow: 0 20px 75px rgba($black, 0.13);
	color: $gray-800;
	> h1 {
		margin-top: 0;
		font-size: 22px;
		margin-bottom: 20px;
		font-weight: 700;
	}
	> h3 {
		margin: 0;
		font-size: 18px;
		margin-bottom: 20px;
	}
}
.react-confirm-alert-svg {
	position: absolute;
	top: 0;
	left: 0;
}
.react-confirm-alert-button-group {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}
@-webkit-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-moz-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-o-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.close-btn {
	border: none;
	background: transparent !important;
	color: $black !important;
	font-size: 20px;
	cursor: pointer;
	font-weight: bold;
	text-shadow: none;
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.search-wrapper {
	border: none !important;
	padding: 0px !important;
}
.width-300 {
	width: 230px;
	display: inline-block;
	margin: 0px 10px;
	text-align: left;
	vertical-align: top;
}
.error-message {
	position: absolute;
	color: $red;
	font-size: 12px;
}
.form-error-msg {
	color: $darkred;
	font-size: 12px;
	padding-top: 2px;
	padding-bottom: 5px;
}
.modal-event {
	form {
		label {
			margin-bottom: 0.2rem !important;
		}
	}
	.form-group {
		margin-bottom: 0.2rem !important;
	}
}
.event__filter_date {
	margin-right: 10px;
	font-weight: 700;
}
#__react-alert__ {
	> div {
		z-index: 9999 !important;
		div {
			div {
				background-color: $gray-50 !important;
				color: $black !important;
			}
		}
	}
}
.text-primary {
	color: $white !important;
}
.dx-g-bs4-header-cell-content {
	cursor: pointer;
}
.link-type {
	background: transparent;
	border: none;
	padding: 5px;
	&:hover {
		color: $jellybean;
	}
}
.open {
	> .dropdown-menu {
		display: block;
	}
}
.dropdown-menu {
	min-width: 160px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	padding: 5px 0;
	margin: 0;
	font-size: 14px;
	> li {
		> a {
			display: block !important;
			padding: 3px 20px !important;
			clear: both !important;
			font-weight: 400 !important;
			line-height: 1.42857143 !important;
			color: $gray-700 !important;
			white-space: nowrap !important;
		}
	}
}
.dropzone {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: $gray-200;
	border-style: dashed;
	background-color: $gray-50;
	color: $gray-300;
	outline: none;
	transition: border 0.24s ease-in-out;
}
.react-datetime-picker__wrapper {
	width: 100%;
	border: 0 !important;
}
.react-datetime-picker {
	width: 100%;
}

.table {
	td,
	th {
		border: 1px solid $gray-200;
		text-align: center;
		min-width: 60px;
	}
	td.customeedit {
		input {
			border: 1px solid $gray-200;
			border-radius: 3px;
			padding: 5px;
			&:focus {
				outline: none !important;
				border: 2px solid $ltblue;
			}
		}
	}
	input[type="checkbox"] {
		height: 20px;
		width: 20px;
		vertical-align: middle;
	}
	.link-button {
		width: 100%;
		text-align: center;
		background: transparent;
		border: 0;
	}
	.savefield-button {
		text-align: center;
		background: transparent;
		border-top: 1px solid $gray-200;
		border-right: 1px solid $gray-200;
		border-bottom: 1px solid $gray-200;
		border-left: none;
		min-width: 22px;
		padding: 0;
		text-align: center;
		transition:
			border-color 0.15s ease-in-out,
			box-shadow 0.15s ease-in-out;
		+ .savefield-button {
			border-radius: 0 5px 5px 0;
		}
	}
}
.savebtn {
	width: 40px;
	margin: 0;
	padding: 0;
}
.fade.modal.show {
	position: fixed;
	top: 0;
	border: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.notes-list {
	border-radius: 10px;
	margin-bottom: 10px;
}
.notes-list-item {
	padding: 10px;
	padding: 10px;
	border: 1px solid $gray-200;
}
.import-demo {
	color: $red;
	cursor: pointer;
}
.modal-title {
	margin: 0;
	line-height: 1.42857143;
	float: left;
}

.fa-info-circle {
	&:before {
		content: "\f05a";
		color: $jellybean;
	}
}
.fa-plus-circle {
	&:before {
		content: "\f055";
		color: $jellybean;
	}
}
.p0 {
	padding: 0;
}
.input-text.no-outline.form-control {
	padding: 0 !important;
}
.qty-order {
	background: transparent;
	border: 0;
	padding: 0px;
	width: 100%;
	text-align: center !important;
	outline: none;
}
.qty-order-left {
	background: transparent;
	border: 0;
	padding: 0px;
	width: 100%;
	text-align: left !important;
	outline: none;
}
.qty-order-details {
	padding: 5px;
	width: 100%;
	text-align: center !important;
	outline: none;
	border: 1px solid $gray-200;
	border-radius: 5px;
	text-align: left;
}
.empty {
	padding: 0px;
}
.dark-border {
	border-top: 2px solid $black !important;
}
.blue-color {
	color: $dkblue !important;
	font-weight: bold !important;
}
.load-jobs {
	padding: 0px;
	box-sizing: border-box;
}
.nav {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	li {
		width: 100%;
	}
}
.regular-checkbox {
	display: none;
}
.chckbox {
	background-color: $gray-50;
	border: 1px solid $gray-300;
	box-shadow:
		0 1px 2px rgb(0 0 0 / 5%),
		inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}
.float-left {
	float: left;
}
.item-label {
	margin-left: 5px;
	color: $black;
	font-weight: 500;
	max-width: 100%;
	margin-bottom: 5px;
}
.email-copy {
	width: 60% !important;
	margin: auto;
}
table.fixed {
	table-layout: fixed;
}
ul.rightbtncontainer {
	li {
		a {
			i {
				font-size: 22px;
			}
		}
		a.img-action {
			// img {
			// 	height: 30px !important;
			// 	padding-bottom: 7px;
			// }
		}
	}
}
ul.dropdown-menu {
	li {
		border: 0 !important;
	}
}
.sr-page-link {
	color: $jellybean !important;
	font-weight: 700 !important;
}
td.red-border {
	input {
		border: 2px solid $red !important;
		border-radius: 3px;
		padding: 5px;
	}
}
.overflow-auto {
	overflow: auto;
}
.search-input {
	color: $black;

	padding: 10px 15px;
	width: 100%;

	border-radius: 0;
	background-color: transparent;
	font-size: 14px;
	line-height: 1.42857143;
	border: 1px solid $gray-300;
	border-left: 0;
	border-right: 0;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	transition:
		border-color ease-in-out 0.15s,
		box-shadow ease-in-out 0.15s;
	&:focus {
		border-color: $gray-500;
		outline: 0;
		// -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
		// box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
	}

	&::-moz-placeholder {
		color: $black;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: $black;
	}

	&::-webkit-input-placeholder {
		color: $black;
	}

	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}
}
.list-style-none {
	list-style: none;
}
.gray-text {
	color: $gray-400;
}
.mlt15 {
	margin-left: 15px !important;
}
.attachment {
	margin-right: 6px;
	margin-left: 10px;
}
.img-block {
	display: inline-block;
}
.link {
	cursor: pointer !important;
}

.tt-container {
	position: relative;
	color: $white;
	display: block;
	&:hover {
		color: $white;
		.tt {
			display: block;
			position: absolute;
			color: $black;
			padding: 10px;
			width: 100%;
			white-space: normal;
			background: $white;
			border: 1px solid $gray-200;
			box-shadow: 2px 2px 4px $gray-200;
			border-radius: 10px;
			margin-top: 10px;
			z-index: 99;
		}
		.w-500 {
			width: 500px;
		}
		.ttBal {
			display: block;
			position: absolute;
			color: $black;
			padding: 10px;
			width: 100%;
			white-space: normal;
			background: $white;
			border: 1px solid $gray-200;
			box-shadow: 2px 2px 4px $gray-200;
			border-radius: 10px;
			margin-top: 3px;
			z-index: 99;
		}
		.w-300 {
			width: 300px;
		}
	}
	.tt {
		display: none;
	}
	.ttBal {
		display: none;
	}
}
.height-100 {
	height: 100px !important;
}
.height-67 {
	height: 67px !important;
}
.search {
	background-image: url(../images/search.png);
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: right 16px center;
	//background-color: $gray-100 !important;
	font-size: 14px;
	padding-right: 4.25rem;
}
.react-datetime-picker__button {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.w300 {
	width: 300px;
}

.disabled {
	pointer-events: none;
	opacity: 0.6;
}
.width70 {
	min-width: 70px;
}
.hide-in-mobile {
	display: block;
}
.hide-in-desktop {
	display: none;
}
.top-filter {
	min-height: auto;
	width: 100%;
	z-index: 999;
	//text-align: center;
	padding-bottom: 10px;
	position: relative;
}
.releaseqty {
	border: 1px solid $gray-200 !important;
	border-radius: 3px;
	padding: 3px;
}
.exportbtn {
	a {
		color: $white !important;
	}
}
.count-selected {
	top: -17px !important;
	right: -6px !important;
	position: relative;
	z-index: 2;
	border: 1px solid;
	font-size: 13px;
	font-weight: bold;
}
.width22 {
	width: 22px;
}
.selectedcount {
	border-radius: 11px;
	padding: 4px 6px;
	border: 1px solid $gray-400;
	background-color: $white;
	color: $gray-400;
}
.table-responsive {
	table {
		thead {
			th {
				vertical-align: middle;
			}
		}
		tr {
			td {
				vertical-align: middle !important;
				vertical-align: middle !important;
			}
		}
	}
}
.pt-mt-bt-tbl-header {
	.table-responsive {
		min-height: 400px;
	}
	// .dx-g-bs4-header-cell-content {
	// 	cursor: default;
	// }
}
.border-bottom {
	border-bottom: 1px solid !important;
}
.height-59 {
	height: 59px !important;
}
.mt-db-part-desc {
	white-space: pre-wrap;
}
.react-calendar {
	font-size: 14px;
	line-height: 1em;
}
.bt-order-action-icon {
	padding: 7px 2px !important;
	button {
		width: 22px !important;
		padding-right: 30px;
		font-size: 20px;
		img {
			width: 38px !important;
			margin-left: -5px;
			vertical-align: middle;
			margin-bottom: 4px;
		}
	}
}
.release-action-icon {
	padding: 7px 17px !important;
	button {
		font-size: 18px;
		width: 16px !important;
		padding-right: 30px !important;
	}
}
.w275 {
	width: 275px;
}
.width50 {
	width: 50px;
}
.viewimages {
	width: 60px;
	height: 60px;
	margin-top: 5px;
}
.imgdiv {
	display: inline-block;
}
.imgdelete {
	width: 0 !important;
	top: -20px;
	position: relative;
	left: 49px;
	i {
		background: white;
		line-height: 0.5;
	}
}
.mt-admin-video-img {
	padding: 0px !important;
	float: right;
	margin-left: 5px;
	img {
		width: 29px;
		border-radius: 50%;
		cursor: pointer;
	}
}
.activeFilter {
	border-bottom: 5px solid $yellow;
}
.text-blue1 {
	color: $blue !important;
}
.pt-mt-copy-job-box {
	overflow-y: auto;
	max-height: 275px;
}
.pt-mt-uom-icon {
	.css-g1d714-ValueContainer {
		background: none;
		// height: 36px !important;
	}
	.css-1uccc91-singleValue {
		width: 100%;
		margin-left: -5px;
	}
	.css-yk16xz-control {
		cursor: pointer;
	}
}
.tbl-bom-details {
	// border: 0.2px solid $gray-200;
}
.inpt-box {
	font-size: 14px;
	padding-top: 0.65rem;
	padding-bottom: 0.65rem;
}
.inpt-bo-number {
	background: transparent !important;
}
.lead-time-link {
	border-bottom: 2px solid;
  color: $jellybean;
}
table.tbl-buy-order-items {
	td {
		border: 1px solid $gray-200 !important;
		vertical-align: middle;
		input {
			font-size: 14px !important;
		}
		> input[type="checkbox"] {
			vertical-align: middle;
		}
	}
}
.bordertop {
	border-top: 4px solid $navyblue;
	border-left: 4px solid $navyblue;
	border-right: 4px solid $navyblue;
}
.borderbottom {
	border-bottom: 4px solid $navyblue;
	border-left: 4px solid $navyblue;
	border-right: 4px solid $navyblue;
}
.gallery_modal {
	.carousel {
		.slide {
			img {
				width: 100%;
				vertical-align: top;
				border: 0;
				max-height: calc(100vh - 291px);
				max-width: 100%;
			}
			display: flex;
			align-content: center;
			justify-content: center;
			flex-wrap: wrap !important;
		}
		.thumbs-wrapper {
			margin: 12px 0 0;
		}
		.thumb {
			height: 80px;
			vertical-align: top;
		}
		img {
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		.control-dots {
			margin: 0;
			line-height: 8px;
			.dot {
				vertical-align: top;
				background-color: $navyblue;
				box-shadow: none;
				opacity: 1;
			}
			.dot.selected {
				background-color: $blue;
			}
		}
		.carousel-status {
			background: $navyblue;
			text-shadow: none;
			padding: 0px;
			left: 0px;
			right: 0px;
			width: 60px;
			margin: 0px auto;
			line-height: 24px;
			font-size: 12px;
		}
	}
	.carousel.carousel-slider {
		padding-top: 36px;
		padding-bottom: 20px;
		.control-arrow {
			opacity: 1;
			visibility: visible;
			background: $navyblue;
			height: 80px;
			margin: auto;
			&:hover {
				background: $blue;
			}
		}
	}
}

.pt-mt-bt-vendor-field {
	.css-g1d714-ValueContainer {
		background: none;
		// height: 36px !important;
	}
	.css-yk16xz-control {
		cursor: pointer;
	}
}
.pt-mt-bt-ponum {
	min-width: 130px;
}
.pt-mt-bt-col {
	padding: 3px 11px;
}
.text-left-align {
	text-align: left !important;
}
.inpt-border-0 {
	border: 0 !important;
	background: transparent !important;
}
.import-notice {
	.highlight-text {
		border-bottom: 1px solid $ltred;
	}
}
.wrapper-import-message {
	margin-top: 20px;
	font-size: 16px;
	.ul-list-square {
		li {
			line-height: 30px;
		}
	}
}
.ul-list-square {
	list-style-type: square;
}
.input-error-msg {
	margin-left: -20px;
	padding-top: 5px;
	color: red;
	display: table-cell;
	text-align: left;
}
.containerCheckbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			~ {
				.checkmark {
					background-color: $blue;
					&:after {
						display: block;
					}
				}
			}
		}
	}
	&:hover {
		input {
			~ {
				.checkmark {
					background-color: $gray-200;
				}
			}
		}
	}
	.checkmark {
		&:after {
			left: 6px;
			top: 3px;
			width: 5px;
			height: 10px;
			border: solid white;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
.jobselect {
	> span {
		+ {
			div {
				> div {
					&:first-child {
						> div {
							&:first-child {
								// color: #b90000;
								font-size: 14px;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
}
.highlighted-checkbox {
	background-color: $gray-60;
	border: 2px solid $black;
}
.margin-auto {
	margin: auto !important;
}
.table-order {
	overflow: auto;
	width: 100%;
	max-width: 100%;
	table {
		width: 100%;
		// min-width: 1102px;
		border-collapse: collapse;
		table-layout: fixed;
		text-align: left;
		thead {
			tr {
				th {
					border: 1px solid $gray-200;
					font-weight: normal;
					padding: 12px 15px;
          .custom-input {
            width: 100%;
            margin-left: 10px;
            padding: 12px;
            border: 2px solid $black;
            border-radius: 5px;
            margin: 0;
          }
				}
			}
		}
		tbody {
      tr.row-hightlight  {
        background:#F0FFFA;
        border: 1px solid $gray-200;
      }
      tr.row-box  {
        border: 1px solid $gray-200;
      }
      tr.row-history  {
        border: 1px solid $gray-200;
        div {
          color:$gray-500;
          padding-bottom: 7px;
        }
      }
      tr {

				td {
					padding: 12px 15px;
				}
				/*&:nth-child(2n-1) {
					td {
						background-color: $gray-60;
					}
				}*/
			}
		}
		label {
			text-decoration: underline;
			color: $gray-500;
		}
	}
}
.order-span {
	white-space: nowrap;
}
.pt-mt-add-pd {
	.form-group {
		margin-bottom: 10px;
		label {
			margin-bottom: 0;
		}
	}
}
.pt-mtdb-list-col1 {
	width: 160px;
	white-space: normal;
}
.pt-mtdb-list-col2 {
	width: 180px;
	white-space: normal;
}
.pt-mtdb-list-col3 {
	width: 200px;
	white-space: normal;
}
.pt-mtdb-list-col4 {
	max-width: 120px;
	white-space: normal;
}
.pt-mt-alert {
	text-transform: initial;
}
.tbl-rd-email-list {
	max-height: 350px !important;
	overflow-y: scroll;
}
.pt-mt-md-inactive-icon {
	margin-right: 40px;
	font-size: 16px;
	font-weight: 500;
	color: $dkblue;
	margin-right: 40px;
	display: flex;
	align-items: center;
}
.toggle-switch {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 22px;
	margin: 0 6px 0 0;
	input[type="checkbox"] {
		display: none;
		&:checked {
			+ {
				.switch {
					&::before {
						transform: translateX(13px);
						background-color: $white;
					}
					background-color: $jellybean;
				}
			}
		}
	}
	.switch {
		position: absolute;
		cursor: pointer;
		background-color: $gray-200;
		//border: 1px solid $gray-200;
		border-radius: 25px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: background-color 0.2s ease;

		&::before {
			position: absolute;
			content: "";
			left: 2px;
			top: 2px;
			width: 18px;
			height: 18px;
			background-color: $white;
			border-radius: 50%;
			transition: transform 0.3s ease;
			//border: 1px solid $gray-200;
		}
	}
}

.text-custom-gray {
	font-style: italic;
	color: $gray-600;
	opacity: 0.6;
}

.row-text-gray {
	color: $gray-400;
	opacity: 0.6;
}
.col-att-file {
	width: 15%;
	.file-name {
		font-weight: 700;
		word-break: break-word;
	}
	a {
		font-weight: 700;
		margin-left: -2px;
		&:hover {
			color: $navyblue;
		}
	}
	.btn-action {
		margin-bottom: 10px;
	}
	.file-up-date {
		margin-top: 5px;
		margin-bottom: 5px;
		font-weight: 700;
	}
}
.mrl-5 {
	margin-left: 25px;
}
.wrapper-wcut-form {
	.col-form-label {
		color: $gray-400;
		padding-right: 0;
		font-size: 14px;
		background-color: $white;
		background-clip: padding-box;
		@media (min-width: 768px) {
			text-align: right;
		}
	}
	.form-control {
		height: 38px !important;
		font-size: 14px !important;
		width: 90% !important;
	}
	.separator {
		border-bottom: 1px solid $gray-200;
		margin: 20px -20px;
	}
}
span#required-field {
	color: $red;
}
span.item-index.feet {
	position: absolute;
	float: initial;
	z-index: 9999;
	display: flex;
	margin-left: 85%;
	margin-top: -10%;
	color: $gray-400;
}
.wire-bunch-item {
	.form-group.row {
		.col-sm-8 {
			.error-message {
				position: inherit;
			}
		}
	}
	.item-index {
		display: contents;
		&.feet {
			margin-top: 0;
			margin-left: 0;
			top: 9px;
			right: 6px;
			font-size: 13px;
		}
	}
	border: 1px solid $gray-200;
	padding: 20px;
	border-radius: 5px;
	padding-top: 20px;
	margin-bottom: 20px;
	.form-group {
		// margin-top: 13px;
		margin-bottom: 15px;
		align-items: center;
		@media (min-width: 768px) {
			margin-bottom: 25px;
		}
	}
}
.wrapper-cutlist {
	border: 1px solid $gray-200;
	border-radius: 5px;
	padding: 15px;
	.item-list {
		padding-bottom: 10px;
		padding-top: 10px;
		.desc {
			font-size: 14px;
			font-weight: 700;
			padding-bottom: 3px;
		}
	}
	.desc-header {
		a {
			font-size: 16px;
			color: $black;
		}
	}
	.item-qty {
		padding-bottom: 5px;
	}
	.tr-desc {
		color: $gray-800;
		font-weight: 500;
		text-align: left;
		background: $gray-60;
	}
}
.top-border {
	&:not(:first-child) {
		border-top: 1px solid rgba($black, 0.2);
	}
}
.desc-header {
	justify-content: space-between;
	display: flex;
}
.wc-release-preview {
	// padding-left: 15px;
	// margin-top: 25px;
  border: 1px solid #ccc;
  border-top: 0px !important;
  border-bottom: 0px !important;
	ul {
    padding: 15px 0;
		// margin-bottom: 25px;
		list-style-type: none;
    border-bottom: 1px solid #ccc;
		li {
      padding-left: 15px;
	    line-height: 25px;
		}
		li.bl-desc {
			font-weight: 500;
			font-size: 16px;
			margin-bottom: 2px;
		}
	}
}
.action-btn {
	margin-left: 5px;
	.btn {
		margin-top: 5px;
	}
}
.edit-fa-icon {
	font-size: 18px;
	font-size: 18px;
}
.delete-fa-icon {
	font-size: 18px;
	font-size: 18px;
}
.tbl-po-lines {
	thead {
		.d-flex {
			display: block !important;
		}
	}
	table {
		.dx-g-bs4-table-summary-item {
			font-size: 16px;
			font-weight: bold;
		}
	}
}
.tbl-report-pp-hours {
	.dx-g-bs4-table-summary-item {
		font-size: 16px;
		font-weight: bold;
	}
}
.btn-po-groups {
	.btn {
		font-size: 13px;
		padding-left: 10px;
		padding-right: 10px;
	}
}
.btn-right-icons {
	.custom-size {
		width: 220px;
	}
}
.inputgp-40 {
	height: 40px;
}
.btn-clear-search {
	border-color: $gray-200;
}
.rightbtncontainer {
	.export-csv {
		a {
			color: $gray-700;
			padding: 0;
			display: block;
			height: 20px;
		}
	}
}
.order-details-info {
	background-color: $white !important;
	min-height: 420px !important;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid $gray-200;
	.title {
		font-size: 16px;
		font-weight: 700;
		display: inline-block;
	}
	.hr {
		margin-right: 0px;
		margin-left: 0px;
	}
	.edit-action {
		display: inline-block;
		float: right;
	}
	.edit-action a {
		font-size: 12px;
		padding: 4px 11px;
	}
}
.btn-order-details-collapse {
	margin-top: 0px;
	box-shadow:
		0 1px 2px rgba(60, 64, 67, 0.3),
		0 2px 6px 2px rgba(60, 64, 67, 0.15);
	height: 23px;
	width: 48px;
	padding: 0 !important;
	border: none;
	border-radius: 0 0 8px 8px;
	background: $white 7px center/7px 10px no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	&:after {
		content: "";
		border-top: 5px solid $black;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		display: inline-block;
		opacity: 0.6;
	}
	&:focus {
		box-shadow:
			0 1px 2px rgba(60, 64, 67, 0.3),
			0 2px 6px 2px rgba(60, 64, 67, 0.15);
	}
	&[aria-expanded="true"] {
		&:after {
			transform: rotate(180deg);
		}
	}
	// i.fa-sort-down {
	// 	vertical-align: text-top;
	// }
	// i.fa-sort-up {
	// 	vertical-align: bottom;
	// }
	&.show-state-btn {
		white-space: nowrap;
		width: 120px;
		justify-content: space-evenly;
		padding: 14px 15px !important;
		font-size: 14px;
		color: $black;
    font-weight: 400;
	}
}
.field-info {
	.label {
		padding-right: 10px;
		color: $gray-500;
		min-width: 120px;
		font-weight: 400;
	}
	.dvalue {
		font-weight: 480;
		text-align: left;
	}
	.detail-info {
		font-size: 16px;
		border-bottom: 1px solid rgba($black, 0.1);
		padding-bottom: 5px;
		margin-bottom: 12px;
		.w275 {
			min-width: 150px !important;
			white-space: nowrap;
		}
	}
	&.field-report {
		.detail-info {
			font-size: 14px;
			padding-bottom: 0;
			padding-top: 0;
			margin-bottom: 0;
			> div {
				padding-bottom: 5px;
				padding-top: 5px;
				&.bg-green {
					background-color: #80fe8f;
				}
				&.dvalue {
					padding-left: 5px;
					padding-right: 5px;
					min-width: 100px;
					text-align: right;
					font-weight: normal;
				}
			}
		}
	}
}
.wrapper-reopen-order {
	position: relative;
	bottom: 7px;
	button {
		padding: 4px 7px;
	}
}
.text-ellipsis {
	max-height: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: max-height 2s ease;
	&:hover {
		max-height: 100rem;
		text-align: left;
		overflow: visible;
		white-space: normal !important;
		transition: max-height 2s ease;
	}
}
.poc-contact {
	> div {
		.poc-name {
			min-width: 85px;
			display: inline-block;
			font-weight: 500;
		}
	}
	// max-height: 75px;
	overflow: hidden;
	transition: max-height 2s ease;
	&:hover {
		overflow: visible;
		max-height: 100rem;
		transition: max-height 2s ease;
	}
}
.multiline-ellipsis {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	white-space: pre-wrap;
}
.btn-order-history {
	color: $gray-500 !important;
	text-decoration: underline !important;
	background: none;
	border: none;
	padding: 0;
}
.order-heading-title {
	font-size: 22px;
	font-weight: 500;
	line-height: 21px;
	.ot-separtor {
		margin: 3px 10px 0px 10px;
		font-size: 25px;
	}
}
.tbl-buy-order-items,
.tbl-commodity-order-items {
	overflow: scroll;
	border-collapse: collapse;
	> thead {
		position: sticky;
		top: 0;
		z-index: 999;
	}
	.subtabel {
		margin: 8px 8px 8px 45px;
	}
	.subtablebuyout {
		margin-bottom: 20px !important;
	}
}

.thead-sticky {
	border-collapse: collapse;
	max-width: 100%;
	max-height: calc(100vh - 320px);
	z-index: 9999;
}
.boxshadow1 {
	background: $gray-60;
	border-radius: 4px;
	border: solid 1px $gray-200;
}
.pt-pid-text {
	white-space: pre-wrap;
}
.min-height-300 {
	min-height: 300px;
}
.orange-color {
	color: orange;
}
.horizontal-scrollable {
	> .row {
		overflow-x: auto;
		white-space: nowrap;
	}
}
.wrapper-box-table {
	.col {
		border-right: 1px solid $gray-200;
		&:first-child {
			border-left: 1px solid $gray-200;
		}
		&:last-child {
			margin-right: 1px;
		}
	}
	.title-header {
		font-size: 16px;
		font-weight: 700;
		border-bottom: 1px solid $gray-200;
		padding: 10px;
	}
	.item {
		padding: 8px 10px;
		// width: 230px;
		border-bottom: 1px solid $gray-200;
	}
	a {
		color: inherit;
		white-space: normal;
		&:hover {
			color: $jellybean;
		}
	}
}
.report-card {
	border-radius: 0;
	border-left: none;
	border-right: none;
}

@media (max-width: 1199px) {
	// .top-filter {
	// 	padding-top: 10px;
	// }

	.width-300 {
		width: calc(50% - 10px);
		margin-right: 5px;
		margin-left: 5px;
	}
	.d-inline-block {
		> .width-300 {
			&:last-child {
				margin-top: 8px;
			}
		}
	}
	.titlerow.title-row-inner {
		margin-top: 87px;
	}
}

@media (max-width: 991px) {
	.search {
		width: 100% !important;
	}
	.hide-in-mobile {
		display: none;
	}
	.hide-in-desktop {
		display: block;
	}
}
@media (min-width: 768px) and (max-width: 800px) {
	.btn-bout-act {
		padding-left: 9px;
		padding-right: 9px;
	}
}
@media (min-width: 574px) and (max-width: 768px) {
	.mtp40 {
		margin-top: 60px;
	}
}
@media (min-width: 575px) and (max-width: 576px) {
	.mtp40 {
		margin-top: 68px;
		padding-bottom: 8px;
	}
}
@media (max-width: 575px) {
	.gallery_modal {
		.carousel {
			.slide {
				img {
					max-height: calc(100vh - 254px);
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.table-order-logo {
		text-align: center;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.react-datepicker {
		min-width: 315px;
	}
	.react-datepicker__time-container {
		width: 100px !important;
	}
}
@media (min-width: 320px) {
	.react-datepicker-popper {
		transform: translate3d(5px, 65px, 0px) !important;
	}
	.react-datepicker__header.react-datepicker__header--time {
		height: 64px !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.react-datepicker__time-container {
		width: 120px !important;
	}
	.react-datepicker__time-box {
		margin: 0px !important;
		width: 120px !important;
	}
	.react-datepicker__navigation--next--with-time {
		&:not(.react-datepicker__navigation--next--with-today-button) {
			right: 125px !important;
		}
	}
}
body {
	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range,
	.react-datepicker__day--in-range,
	.react-datepicker__month-text--selected,
	.react-datepicker__month-text--in-selecting-range,
	.react-datepicker__month-text--in-range,
	.react-datepicker__quarter-text--selected,
	.react-datepicker__quarter-text--in-selecting-range,
	.react-datepicker__quarter-text--in-range,
	.react-datepicker__year-text--selected,
	.react-datepicker__year-text--in-selecting-range,
	.react-datepicker__year-text--in-range {
		background-color: $navyblue;
		&:hover {
			background-color: $jellybean;
		}
	}

	.react-datepicker__time-container {
		.react-datepicker__time {
			.react-datepicker__time-box {
				ul.react-datepicker__time-list {
					li {
						&.react-datepicker__time-list-item {
							line-height: 20px;
							height: auto;
						}
						&.react-datepicker__time-list-item--selected {
							background-color: $navyblue;
							&:hover {
								background-color: $jellybean;
							}
						}
					}
				}
			}
		}
	}
	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 2.3rem;
		line-height: 2.3rem;
	}
	.react-datepicker__navigation {
		top: 7px;
	}
	.react-datepicker__current-month,
	.react-datepicker-time__header,
	.react-datepicker-year-header,
	.react-datepicker__time-box,
	.react-datepicker__month-container {
		font-size: 11px;
	}

	.react-datepicker__header {
		padding: 11px 0 8px;
	}
	.react-datepicker__day-names {
		margin-top: 10px;
	}
}
@media (min-width: 769px) {
	.width45 {
		width: 45%;
	}
}
@media (min-width: 401px) {
	.content-topspace {
		display: inline-block;
		width: 100%;
	}
}
@media (min-width: 576px) {
	// .logobg {
	// 	img {
	// 		width: 50px;
	// 		width: 45px;
	// 	}
	// }

	.content-topspace-sm {
		margin-top: 55px;
	}
	.hd-dropdown {
		top: -6px;
		position: relative;
		z-index: 1;
	}
	.whitebox {
		min-width: 18.8%;
	}
}
@media (min-width: 992px) {
	.content-topspace {
		margin-top: 15px;
	}
	.whitebox {
		min-width: 15.5%;
	}
	.searchbox {
		width: 163px !important;
		min-width: inherit;
	}
	.selectwidth {
		min-width: 80px;
	}
}
@media (min-width: 992px) {
	.numbernav {
		a {
			span.number-txt {
				font-size: 15px;
			}
		}
	}
	.hd-dropdown {
		min-width: 190px;
	}
	.whitebox {
		min-width: 13.3%;
	}
	.searchbox {
		min-width: 70%;
		width: inherit;
	}
}
@media (min-width: 1200px) {
	.font-size22 {
		font-size: 22px;
	}
	.font-size24 {
		font-size: 24px;
	}
	.font-size26 {
		font-size: 26px;
	}
	.font-size28 {
		font-size: 28px;
	}
	.font-size30 {
		font-size: 30px;
	}
	.font-size32 {
		font-size: 32px;
		line-height: 38px;
	}
	.font-size34 {
		font-size: 34px;
	}
	.font-size36 {
		font-size: 36px;
	}
	.font-size38 {
		font-size: 38px;
	}
	.font-size40 {
		font-size: 40px;
	}
	.content-topspace-sm {
		margin-top: 55px;
	}
	#menu-scroll {
		height: calc(100vh - 200px);
	}
	.profilephoto {
		width: 75px;
		height: 75px;
	}
	.profilebox {
		padding-bottom: 15px;
		padding-top: 15px;
	}

	// .logobg {
	// 	img {
	// 		width: 45px;
	// 	}
	// }
	.hd-icon {
		padding: 15px 12px 15px;
	}
	.hd-dropdown {
		top: -3px;
		position: relative;
		min-width: 254px;
		height: calc(1.5em + 0.8rem + 2px);
	}
	// .titlerow {
	// 	position: sticky;
	// 	top: 55px;
	// }
	.whitebox {
		min-width: 8.6%;
	}
	.text-nowrap-xl {
		white-space: normal !important;
	}
}
#content-buyout,
#content {
	background: $white;
	border: 1px solid $gray-100;
	padding: 15px 15px 0;
	margin-bottom: 0;
	display: inline-block;
	width: 100%;
	@media (min-width: 992px) {
		padding: 10px 11px;
	}

	@media (min-width: 1200px) {
		padding: 10px 15px;
	}
}
.midpart {
	padding: 15px;
	font-family: "Roboto", sans-serif;
	@media (min-width: 992px) {
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media (max-width: 991px) {
	.main-content {
		width: 100%;
	}
	header {
		padding: 10px 15px;
		background: $gray-100;
		border-bottom: 1px solid $gray-300;
		&.fixed-top {
			position: relative;
		}
	}
	.header__toggle {
		color: $gray-900;
		cursor: pointer;
		position: relative;
		z-index: 999;
		font-size: 1.5em;
	}
}
body {
	.rmsc {
		--rmsc-main: #3092c0;
		--rmsc-hover: #f0f0f0;
		--rmsc-selected: #e4e4e4;
		--rmsc-border: #ccc;
		--rmsc-gray: #9ca3af;
		--rmsc-p: 4px 12px;
		--rmsc-radius: 4px;
		--rmsc-h: auto;
		.dropdown-heading {
			padding: var(--rmsc-p);
		}
		.dropdown-container {
			min-height: 32px;
			&:focus-within {
				// box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
				box-shadow: none;
				border-color: $gray-500 !important;
			}
		}
	}
	.search input {
		padding: 6px 12px !important;
	}
}
.cat-list {
	margin-right: 8px;
	margin-bottom: 10px;
	> a {
		color: $jellybean;
		font-weight: 500;
		padding: 8px 10px;
		background-color: $white;
		border-radius: 5px;
		display: flex;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		border: 1px solid $jellybean;
		&.active {
      		color: $white;
			border-color: $gray-60;
      		background-color: $jellybean;
			border-radius: 4px;
			.pt-pay-stats-font {
				color: $white;
			}
		}
		&:hover {
			background-color: $jellybean;
			color: $white;
			.pt-pay-stats-font {
				color: $white;
			}
		}
	}
	> button {
		color: $jellybean;
		font-weight: 500;
		padding: 8px 10px;
		background-color: $white;
		border-radius: 5px;
		display: flex;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		border: 1px solid $jellybean;
		&.active {
      		color: $white;
			border-color: $gray-60;
      		background-color: $jellybean;
			border-radius: 4px;
			.pt-pay-stats-font {
				color: $white;
			}
		}
		&:hover {
			background-color: $jellybean;
			color: $white;
			.pt-pay-stats-font {
				color: $white;
			}
		}
	}
	.pt-pay-stats-font {
		cursor: pointer;
		font-size: 15px;
		color: $jellybean;
		font-weight: bold;
	}
}

.hide-card-header {
	.card-header {
		display: none !important;
	}
}

body {
	// .css-26l3qy-menu{
	// 	display: flex;
	// 	overflow: auto;
	// }
	.css-4ljt47-MenuList {
		max-height: 293px;
		// overflow: visible;
		// display: flex;
		// flex-direction: column;
		// width: 100%;
	}
	// 	.css-yt9ioa-option,
	// 	.css-1n7v3ny-option,
	// 	.css-9gakcf-option{
	// 	white-space: nowrap;
	// 	width: auto;
	// }
	.css-b8ldur-Input {
		margin: 0;
	}
	.css-yk16xz-control,
	.css-1pahdxg-control {
		min-height: 30px !important;
	}
	.css-1pahdxg-control {
		// box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
		border-color: $gray-500 !important;
		box-shadow: none;

		outline: none !important;
	}
	.css-yk16xz-control {
		border-color: $gray-200;
		border-radius: 5px;
	}
	.css-tlfecz-indicatorContainer,
	.css-1gtu0rj-indicatorContainer {
		padding: 6px 8px !important;
	}
	.css-g1d714-ValueContainer {
		background: url(../images/search.png);
		background-size: 16px;
		background-repeat: no-repeat;
		background-position: right 10px center;
	}
	.css-yk16xz-control {
		.css-1uccc91-singleValue {
			color: $gray-500;
		}
	}
	.css-1uccc91-singleValue {
		font-size: 14px;
		max-width: calc(100% - 37px);
		color: $gray-800;
	}
}
.filter-table {
	.css-2b097c-container {
		z-index: 11;
	}
}
.header-filter {
	.css-2b097c-container {
		z-index: 11;
	}
}
.header-filter {
	padding: 10px 0 0;
	@media (min-width: 1200px) {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}
}

.header-section {
	border-bottom: 1px solid $gray-200;
	padding-bottom: 10px;
	padding-top: 0;
}

.react-datetime-picker__clear-button,
.react-datetime-picker__calendar-button {
	.react-datetime-picker__button__icon {
		stroke: $gray-500;
	}
	&:hover {
		.react-datetime-picker__button__icon {
			stroke: $gray-800 !important;
		}
	}
}
.bg-light-warning {
	background-color: #fef3c7;
}
input {
	&::-webkit-calendar-picker-indicator,
	&::-webkit-list-button {
		display: none !important;
	}
}
.react-datepicker-popper {
	z-index: 12;
}

[data-tip] {
	position: relative;
}

[data-tip]:before {
	content: "";
	display: none;
	border-top: 1px solid #d1d5db;
	border-left: 1px solid #d1d5db;
	background-color: #fff;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	position: absolute;
	top: 100%;
	margin-top: 4px;
	left: 35px;
	z-index: 8;
	font-size: 0;
	line-height: 0;
}

[data-tip]:after {
	display: none;
	content: attr(data-tip);
	position: absolute;
	top: 100%;
	margin-top: 9px;
	left: 22px;
	padding: 1px 8px;
	background: #fff;
	z-index: 7;
	font-size: 12px;
	height: auto;
	max-width: 250px !important;
	width: auto !important;
	line-height: 20px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 5px;
	word-wrap: break-word;
	border: 1px solid #d1d5db;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

[data-tip="&nbsp;"]:before,
[data-tip="&nbsp;"]:after {
	display: none !important;
}

[data-tip]:hover:before,
[data-tip]:hover:after {
	display: block;
}

.tbl-report-look-summary {
	.dx-g-bs4-table-summary-item {
		font-size: 14px;
		font-weight: bold;
	}
	.dx-g-bs4-table tbody tr {
		&:last-child {
			td {
				font-weight: bold;
			}
		}
	}
}

.pt-look-summ-tbl-th {
	white-space: normal !important;
	padding: 5px 15px 5px 15px !important;
	cursor: default;
	.dx-g-bs4-header-cell-content {
		cursor: default;
	}
	.dx-g-bs4-sorting-indicator {
		display: none;
	}
}
.pt-look-summ-tbl-sat-sub-th {
	background: $tblbgblue !important;
}
.full-order-info {
	height: calc(100% - 10px);
}

.fr-legend-box {
	color: #848484;
	font-size: 16px;
	.left-col {
		text-align: right;
		@media (max-width: 1024px) {
			text-align: left;
		}
	}
	.right-col {
		@media (max-width: 360px) {
			margin-top: 5px;
		}
	}
	@media (max-width: 1024px) {
		font-size: 14px;
	}
}

.boder-bottom-gray {
	border-bottom: solid 1px #ccc;
}
.ptoAvaSearch {
	display: flex;
}
.ptoAvaNameCls {
	width: 275px;
	margin-right: 10px;
}
.ptoAvaHrsCls {
	width: 75px;
	background-color: #fff !important;
	padding: 0.5rem 0.5rem;
}
@media (max-width: 340px) {
	.ptoAvaNameCls {
		width: 175px;
	}
}
.border-state-filter {
  border-bottom: solid 1px #ccc;
}
.tr-hightlight {
  background: $ltsyellow !important;
}
.follow-steps {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	column-gap: 25px;
	row-gap: 20px;
	padding-bottom: 30px;
	li {
		min-width: 120px;
		width: 120px;
		list-style: none;
		position: relative;
		display: flex;
		flex-direction: column;
		a{
			display: flex;
			flex-direction: column;
			height: 100%;
			position: relative;
		}
		&:hover {
			.step-card{
				background-color: $gray-200;
			}
		}
		&.active {
			.step-num{
				background-color: $yellow;
				&:after{
					border-bottom-color: $ltyellow;
				}
			}
			.step-card{
				background-color: $jellybean;
				color: $white;
				&:after{
					background-color: $yellow;
				}
			}
		}
	}
}
.follow-steps-order {
  margin-top: 10px;
}
.follow-steps-items {
  margin-top: -10px;
}
.step-num{
	display: block;
	background-color: $jellybean;
	padding: 8px;
	color: $white;
	text-align: center;
	margin-left: 15px;
	margin-right: 15px;
	border-radius: 8px 0;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: -20px;
	position: relative;
	z-index: 11;
	font-weight: 500;
	box-shadow: 2px 2px 5px rgba($black,0.5);
	&:after{
		content: "";
		position: absolute;
		right: -15px;
		top: 0;
		border-bottom: 19px solid $dkblue;
    border-right: 15px solid transparent;
	}
}
.step-card{
	background-color: $gray-300;
	border-radius: 0 0 20px 20px;
	box-shadow: 5px 3px 8px 1px rgba($black, 0.4);
	padding: 38px 10px 20px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	text-align: center;
	flex: 1;
	color: $gray-900;
	transition: all 0.2s ease-in-out;
	position: relative;
	&:after{
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 50px;
		margin-left: -25px;
		height: 10px;
		border-radius: 5px 5px 0 0;
		background-color: $jellybean;
	}
  .ul-step-card {
    text-align: left;
    margin-left: 6px;
    div {
      padding-bottom: 4px;
    }
  }
}

.btn-as-link {
	border: 0;
	background: #fff;
	padding: 5px 12px;
	font-weight: 400 !important;
	color: $gray-700 !important;
	white-space: nowrap !important;
}
.btn-as-link-txt {
	border: 0;
	background: none;
	padding: 0;
	font-weight: 400 !important;
	color: $blue !important;
	border-bottom: 2px solid;
}