.h5, h5{
	font-size: 2rem;
}
.modal{
	font-family: 'Roboto', sans-serif;
}
.modal-header{
	align-items: stretch;
}
/* checkbox */
.dropdown {
	.dropdown-menu {
		border-radius: 0px;
		min-width: 230px;
		line-height: 22px;
		.dropdown-item {
			white-space: normal;
		}
	}
}
.input-group-append,
.input-group-prepend{
	.btn{
		border-radius: 5px;
	}
}
.form-control {
	color: $gray-800;
	font-weight: 400;
	padding: 0.5rem 1.25rem;
    font-size: 1.5rem;
	border-radius: 5px;
	border-color: $gray-200;
	height: auto;
	line-height: 22px;
	&:focus {
		box-shadow: none;
		border-color: $gray-500;
		~ .input-group-append{
			.btn{
				border-color: $gray-500;
			}
		}
		~ .savefield-button{border-color: $gray-500;}
	}
	&.w-25{
		@media (max-width: 1199px){
			width: 50% !important;
		}
		@media (max-width: 767px){
			width: 100% !important;
		}
	}
}

.btn {
	border-radius: $btn-border-radius;
	padding: $btn-space-y $btn-space-x;
	box-shadow: $btn-box-shadow;
	font-size: $btn-font-size;
	box-shadow: none !important;
	position: relative;
	line-height: $btn-line-height;
	.badge{
		position: absolute;
	}
}
body .daterangepicker .drp-buttons .btn {

	font-size: $btn-font-size;
	font-weight: normal;
	padding: $btn-space-y $btn-space-x;
  }
.btn-primary,
.btn-outline-secondary {
	background: $btn-primary-bg;
	border-color: $btn-primary-bg;
	color: $btn-primary-color;
	box-shadow: none;
	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active{
		background: $btn-primary-bg-hover;
		border-color: $btn-primary-bg-hover;
	}
	&:disabled,
	&.disabled{
		background-color: $btn-primary-bg-disabled;
		border-color: $btn-primary-border-color-disabled;
		color: $btn-primary-color;
	}
}
.btn-danger {
	background: $btn-danger-bg;
	border-color: $btn-danger-bg;
	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active{
		background: $btn-danger-bg-hover;
		border-color: $btn-danger-bg-hover;
	}
	&:disabled,
	&.disabled{
		background-color: $btn-danger-bg-disabled;
		border-color: $btn-danger-border-color-disabled;
	}
}
.btn-warning{
	background-color: $yellow;
	border-color: $yellow;
}
.btn-secondary{
	background-color: $btn-secondary-bg;
	border-color: $btn-secondary-border-color;
	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active{
		background: $btn-secondary-bg-hover;
		border-color: $btn-secondary-bg-hover;
	}
	&:disabled,
	&.disabled{
		background-color: $btn-secondary-bg-disabled;
		border-color: $btn-secondary-border-color-disabled;
	}
}
.btn-outline-cancel{
	background-color: transparent !important;
	color: $gray-500 !important;
	border-color: $btn-secondary-border-color !important;
	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active{
		color: $white !important;
		background: $btn-secondary-border-color !important;
	}
	&:disabled,
	&.disabled{
		background-color: rgba($gray-500,0.8) !important;
		border-color: rgba($gray-500,0.8) !important;
	}
}
.btn-outline-danger{
	color: $btn-o-danger-color;
	border-color: $btn-o-danger-border-color;
	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active{
		background: $btn-o-danger-bg-hover;
		border-color: $btn-o-danger-border-color-hover;
		color: $btn-o-danger-color-hover;
	}
	&:disabled,
	&.disabled{
		color:$btn-o-danger-color-disabled;
	border-color: $btn-o-danger-border-color-disabled;
	}
}
.btn-default {
    color: $gray-500;
    border-color: $gray-500;
	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active{
		background: $gray-500;
		color: $white;
	}
	&:disabled,
	&.disabled{
		color: $gray-500;
    	border-color: $gray-500;
	}
}

.dx-g-bs4-table-cell a {
  color: $jellybean !important;
}

.link-type .fa-edit,
.link-button .fa-edit {
  color: $jellybean !important;
}

.link-button .fa-trash-o {
  color: $darkred !important;
}

label {
	font-size: 14px;
	color: $gray-500;
}

ul.rightbtncontainer {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	>li {
		margin-right: 8px;
		margin-bottom: 5px;
		position: relative;
		list-style: none;
		&:last-child {
			margin-right: 0;
		}
		>a {
			color: rgba($black,0.7) !important;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid $gray-300;
			height: 34px;
			width: 34px;
			border-radius: 5px;
			margin-left: auto;
    margin-right: auto;
cursor: pointer;
			>img {
				width: 20px;
				height: auto;
			}
		}
		.action-name{
			text-align: center;
			font-size: 11px;
		}
		.form-horizontal {
			width: 180px;
		}
		.multiselect-native-select {
			.btn-group {
				.btn {
					border: 0;
					padding: 4px 12px;
				}
			}
			.dropdown-menu {
				.input-group {
					.btn {
						border: 1px solid $gray-200;
						padding: 6px 12px;
					}
				}
			}
		}
		select {
			border: 0;
			padding: 4px 12px;
			height: 28px;
		}
		.multiselect-container {
			>li {
				display: block;
				border: 0;
				>a {
					padding: 0;
					height: auto;
					display: block;
					>label {
						padding: 5px 10px 5px 36px;
					}
				}
			}
		}
		.dropdown-menu.download-menu {
			right: 0;
			left: auto;
			min-width: 300px;
			li {
				display: block;
				//border: 0px !important;
				margin: 0;
				+ li{
					border-top: 1px solid $gray-200 !important;
				}
				>a {
					display: block;
					padding: 6px 12px !important;
					height: auto;
				}
			}
		}
	}
}
.regular-checkbox {
	display: none;
	background-color: $gray-50;
	border: 1px solid $gray-300;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.page-link{
	color: $gray-900;
	border-color: $gray-200;
	padding: 0.65rem 1rem;
	&:focus{
		box-shadow: none;
	}
	&:hover{
		color: $gray-900;
		background-color: $gray-100;
		border-color: $gray-200;
	}
}
.page-item.active{
	.page-link{
		background-color: $primary;
		border-color: $primary;
		color: $white;
	}
}

/*.modal-xxl {
  width: 1350px;
  position: absolute;
  left: 20%;
  top: 20%;
  transform: translate(-30%, 15%);
}*/
.modal-header{
	padding: 2rem;
}
.modal-body{
	padding: 2rem;
}

.modal-footer{
	padding: 1.5rem 2rem;
	>* {
		margin-top: 0;
		margin-left: 0;

}
}
