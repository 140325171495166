
body {
	.fc-theme-standard {
		th {
			border-bottom: none;
			color: $gray-500;
			font-size: 11px;
			font-weight: 500;
			line-height: 13px;
			border-color: $gray-300;
			&:last-child {
				border-right: none;
			}
		}
		td {
			border: 1px solid $gray-200;
			background-color: transparent !important;
			&:last-child {
				border-right: none;
			}
		}
	}
	.fc {
		.fc-col-header-cell-cushion {
			display: inline-block;
			padding: 7px 0 0 0;
			color: $gray-500;
		}
		.fc-daygrid-day-top {
			justify-content: center;
			margin-top: 2px;
		}
		.fc-daygrid-day-number {
			position: relative;
			z-index: 4;
			padding: 0;
			cursor: pointer;
			margin-top: 0px;
			height: 24px;
			line-height: 24px;
			text-align: center;
			background-color: transparent;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			color: $gray-700;
			width: 24px;
			&:hover {
				background-color: $jellybean;
				color: $white;
			}
		}
		.fc-daygrid-event {
			margin-top: 2px;
			padding: 0 4px;
			line-height: 16px;
			font-weight: 500;
			text-transform: capitalize;
			font-size: 12px;
		}
		.fc-daygrid-day-events {
			margin-top: 0;
		}
		.fc-daygrid-body-balanced {
			.fc-daygrid-day-events {
				top: 30px;
			}
		}
		.fc-day-other {
			.fc-daygrid-day-top {
				opacity: 1;
			}
			.fc-daygrid-day-number {
				color: $gray-500;
			}
		}
		.fc-toolbar{
			flex-wrap: wrap;
			&.fc-header-toolbar {
				margin-bottom: 0;
				padding: 20px 20px 20px 75px;
			}
		}
		.fc-button {
			border: 1px solid $gray-200 !important;
			background: transparent !important;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: .25px;
			opacity: 1;
			color: $gray-700 !important;
			text-transform: capitalize;
			padding: 7px 12px 5px 12px;
			&:not([disabled]) {
				&:hover {
					background-color: $gray-50 !important;
				}
			}
		}
		.fc-button.fc-button-active {
			background-color: $gray-50 !important;
		}
		.fc-toolbar-chunk {
			margin-bottom: 6px;
			&:first-child {
				.fc-button {
					.fc-icon {
						vertical-align: top;
					}
				}
			}
			&:nth-child(2) {
				margin-right: auto;
				margin-left: 20px;
			}
		}
		.fc-toolbar-title {
			margin: 0;
			color: $gray-700;
			font-size: 22px;
			font-weight: 400;
			letter-spacing: 0;
			line-height: 28px;
			white-space: nowrap;
		}
		.fc-button-primary {
			&:not(:disabled) {
				&:active,
				&.fc-button-active {
					&:focus {
						box-shadow: none;
					}
				}

			}

		}
		.fc-timegrid-slot {
			min-width: 40px;
		}
		.fc-timegrid-axis-cushion,
		.fc-timegrid-slot-label-cushion {
			padding: 0 15px 0 10px;
			color: $gray-500;
			font-size: 10px;
			-webkit-font-smoothing: subpixel-antialiased;
			position: relative;
			top: -13px;
			background: $white;
		}
		.fc-daygrid-body-natural {
			.fc-daygrid-day-events {
				margin-bottom: 0;
			}
		}
	}
	.fc-liquid-hack {
		.fc-daygrid-day-frame {
			margin-top: 4px;
			margin-bottom: 4px;
		}
	}
	.fc-direction-ltr {
		.fc-daygrid-event.fc-event-start {
			margin-left: 0;
		}
		.fc-daygrid-event.fc-event-end {
			margin-right: 8px;
		}
		.fc-toolbar {
			>* {

				&>:not(:first-child) {
					margin-left: 0;
					margin-right: 20px;
				}

			}
		}
		.fc-timegrid-slot-label-frame {
			text-align: left;
		}
	}
	.fc-direction-rtl {
		.fc-daygrid-event.fc-event-end {
			margin-left: 0;
		}
		.fc-daygrid-event.fc-event-start {
			margin-right: 8px;
		}
	}
	.fc-toolbar-chunk {
		&:first-child {
			flex-direction: row-reverse;
			display: flex;
			.fc-button-group {
				.fc-button {
					border-color: transparent !important;
					padding-left: 0 !important;
					padding-right: 0 !important;
					min-width: 32px;
					background-color: transparent !important;
				}
			}
		}
	}
	.fc-timegrid-divider.fc-cell-shaded {
		display: none;
	}
	.fc-timegrid-body {
		tbody {
			tr {
				&:first-child {
					td {
						&:first-child {
							.fc-timegrid-slot-label-frame {
								display: none;
							}
						}
					}
				}
				&:nth-child(2n-1) {
					td {
						border-bottom: none !important;
					}
				}
				&:nth-child(2n) {
					td {
						border-top: none !important;
					}
				}
			}
		}
	}
	.fc-daygrid-event {
		cursor: pointer;
	}
}
.modal {
	z-index: 9999;
}
.modal-event {
	.form-control {
		background-color: rgba(255,255,255,0);
		color: $gray-700;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		min-width: 0%;
		outline: none;
		height: auto;
		vertical-align: top;
		appearance: none;
		resize: none;
	}
	select.form-control {
		background-image: url("../images/icon-select.svg");
		background-repeat: no-repeat;
		background-size: 12px;
		background-position: right .75rem center;
		padding-right: calc(.75rem * 3);
	}
	option {
		padding: 0;
	}
}
.add-event {
	width: 35px;
	height: 35px;
	display: inline-block;
	line-height: 35px;
	text-align: center;
	background: $navyblue;
	color: $white;
	border-radius: 50%;
	position: absolute;
	top: 20px;
	left: 20px;
	cursor: pointer;
}
.modal-event-view {
	ul {
		list-style: none;
		border: none;
		li {
			border-bottom: 1px solid rgba($black,0.1);
			display: flex;
			align-items: center;
			padding: 15px 0px;
			font-size: 14px;
			label {
				font-weight: 600;
				color: $black;
				min-width: 140px;
				margin-bottom: 0;
				font-size: 14px;
			}
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
	}
	.modal-header {
		a {
			color: $black;
			font-size: 20px;
			&:hover {
				opacity: 0.5;
			}
		}
	}
}
.fc-direction-ltr {
	.fc-daygrid-block-event {
		&:not(.fc-event-start) {
			border-radius: 3px !important;
			border-width: 1px !important;
		}
	}
}
.fc-direction-rtl {
	.fc-daygrid-block-event {
		&:not(.fc-event-end) {
			border-radius: 3px !important;
			border-width: 1px !important;
		}
	}
}
.fc-day-today {
	a.fc-daygrid-day-number {
		background: $jellybean;
		color: $white;
		font-weight: 700;
	}
}
.fc-daygrid-day-events,
.fc-daygrid-event-harness  {
	a.completed-event {
		color: $gray-300 !important;
	}
	a.emergency-event {
		color: $red !important;
	}
}

.fc-daygrid-block-event,
.fc-timegrid-event {
	.fc-event-title {
		color: $white !important;
	}
}

.noteIcon {
	.fc-event-title {
		&:before {
			font-family: $font-awesome;
			content: "\f05a";
			display: inline-block;
			padding-right: 3px;
			font-weight: 900;
			color: $yellow;
		}
	}
}
.bs-tooltip-top {
	z-index: 9999 !important;
}
.tooltip-inner {
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	color: $black;
	font-size: 12px;
	text-align: center;
	border: 1px solid $gray-300;
	background-color: $gray-200;
	border-radius: 0.25rem;
	font-weight: 600;
}
.btn-action {
	.fa-toggle-on {
		&:before {
			color: $navyblue;
		}
	}
}
.btn-action-view {
	span {
		font-size: 26px !important;
		vertical-align: middle !important;
	}
}
@media (max-width:767px) {
	body {
		.fc {
			.fc-toolbar.fc-header-toolbar {
				padding: 10px 10px 10px 55px;
			}
			.fc-toolbar-chunk {
				&:nth-child(2) {
					margin-left: 10px;
				}
			}
			.fc-toolbar-title {
				font-size: 18px;
			}
		}
		.fc-direction-ltr {
			.fc-toolbar {
				>* {

						&>:not(:first-child) {
							margin-right: 10px;
						}

				}
			}
		}
	}
	.add-event {
		top: 10px;
		left: 10px;
	}
	.fc-daygrid-block-event {
		.fc-event-time,
		.fc-event-title {
			white-space: initial;
		}
	}
}
@media (max-width:575px) {
	.modal-event-view {
		ul {
			li {
				flex-wrap: wrap;
				label {
					width: 100%;
				}
			}
		}
	}
	body {
		.fc {
			.fc-toolbar-chunk {
				// &:nth-child(2) {
				// 	order: 1;
				// 	width: 100%;
				// }
				// &:nth-child(1),
				// &:nth-child(3) {
				// 	order: 2;
				// }
			}
			.fc-toolbar.fc-header-toolbar {
				flex-wrap: wrap;
				padding: 10px;
			}
			.fc-button {
				padding: 7px 5px 7px 5px;
			}
			.fc-toolbar-title {
				font-size: 18px;
				text-align: center;
				line-height: 35px;
				// margin-bottom: 10px;
			}
		}
		.fc-toolbar-chunk {
			&:first-child {
				flex-direction: row;
				.fc-button-group {
					.fc-button {
						min-width: 25px;
					}
				}
			}
		}
		.fc-direction-ltr {
			.fc-toolbar {
				>* {

						&>:not(:first-child) {
							margin-right: 5px;
						}

				}
			}
		}
	}
	.fc {
		.fc-popover {
			max-width: 280px;
		}
	}
}
