
/* Mobile first layout SmartMenus Core CSS sm-core-css.css (it's not recommended editing these rules)
   You need this once per page no matter how many menu trees or different themes you use.
-------------------------------------------------------------------------------------------*/
/* .sm ul{display:none;} */
/*------------------------------------------
sm-blue.css
------------------------------------------*/
/*# sourceMappingURL=sm-blue.css.map */
/* .menu-item-has-children:after{
  content: '▼';
  top: 50%;
  right: 0;
  margin-top: -10px;
  font-size: 22px;
  position: absolute;
}

.menu-item-has-children .menu-item-has-children:after{
  content: ' \25BA';
  top: 50%;
  right: 0;
  margin-top: -10px;
  font-size: 22px;
  position: absolute;
} */
/* #top-menu > li > ul >li a {
  position: relative;
   float: left; 
   clear: left; 
} */
.sm {
	box-sizing: border-box;
	position: relative;
	z-index: 9999;
	-webkit-tap-highlight-color: rgba($black,0);
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	line-height: normal;
	direction: ltr;
	text-align: left;
	ul {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
		line-height: normal;
		direction: ltr;
		text-align: left;
	}
	li {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
		line-height: normal;
		direction: ltr;
		text-align: left;
		position: relative;
	}
	>li {
		border-bottom: 1px solid $gray-200;
    margin-bottom: 5px;
    margin-top: 5px;
		>h1,>h2,>h3,>h4,>h5,>h6 {
			margin: 0;
			padding: 0;
		}
		a{
			&.active, &.highlighted {
				background: $navyblue;
				color: $white;
			}
		}
		
	}
	a {
		position: relative;
		display: block;
		&.disabled {
			cursor: default;
		}
	}
	&::after {
		content: "";
		display: block;
		height: 0;
		font: 0px/0 serif;
		clear: both;
		overflow: hidden;
	}
	* {
		box-sizing: inherit;
		&::before,&::after {
			box-sizing: inherit;
		}
		
	}
}
.sm-rtl {
	direction: rtl;
	text-align: right;
	ul,li {
		direction: rtl;
		text-align: right;
	}
	
}
.sm-blue {
	a {
		padding: 0.5rem 35px 0.5rem 1.5rem;
		padding-right: 35px;
		color: $gray-600;
		// font-size: 16px;
		// font-weight: 400;
		// line-height: 23px;
		text-decoration: none;
		
		.sub-arrow {
			position: absolute;
			top: 50%;
			margin-top: -13px;
			left: auto;
			right: 9px;
			width: 26px;
			height: 26px;
			overflow: hidden;
			text-align: center;
			text-shadow: none;
			padding-top: 1px;
			&::before {
				content: "\f054";
				font-size: 12px;
				font-family: $font-awesome;
				font-weight: 600;
				font-style: normal;
			}
		}
		i {
			margin-right: 0.5em;
			font-size: 1.2em;
		}
		&.current {
			background: $jellybean;
			color: $white;
		}
		&.disabled {
			color: $ltblue;
		}
		&.highlighted {
			.sub-arrow {
				&::before {
					content: "\f078";
					font-size: 12px;
					font-family: $font-awesome;
					font-weight: 600;
					font-style: normal;
				}
			}
		}
	}
	>li {
		&:last-child {
			>a {
				border-radius: 0 0 0px 0px;
				&.highlighted {
					border-radius: 0;
				}
			}
			>* {
				&:not(ul) {
					a {
						border-radius: 0 0 0px 0px;
						&.highlighted {
							border-radius: 0;
						}
					}
				}
			}
			>ul {
				border-radius: 0 0 0px 0px;
				>li {
					&:last-child {
						>a {
							border-radius: 0 0 0px 0px;
							&.highlighted {
								border-radius: 0;
							}
						}
						>* {
							&:not(ul) {
								a {
									border-radius: 0 0 0px 0px;
									&.highlighted {
										border-radius: 0;
									}
								}
							}
						}
						>ul {
							border-radius: 0 0 0px 0px;
							>li {
								&:last-child {
									>a {
										border-radius: 0 0 0px 0px;
										&.highlighted {
											border-radius: 0;
										}
									}
									>* {
										&:not(ul) {
											a {
												border-radius: 0 0 0px 0px;
												&.highlighted {
													border-radius: 0;
												}
											}
											
										}
									}
									>ul {
										border-radius: 0 0 0px 0px;
										>li {
											&:last-child {
												>a {
													border-radius: 0 0 0px 0px;
													&.highlighted {
														border-radius: 0;
													}
												}
												>* {
													&:not(ul) {
														a {
															border-radius: 0 0 0px 0px;
															&.highlighted {
																border-radius: 0;
															}
														}
													}
												}
												>ul {
													border-radius: 0 0 0px 0px;
													>li {
														&:last-child {
															>a {
																border-radius: 0 0 0px 0px;
																&.highlighted {
																	border-radius: 0;
																}
															}
															>* {
																&:not(ul) {
																	a {
																		border-radius: 0 0 0px 0px;
																		&.highlighted {
																			border-radius: 0;
																		}
																	}
																}
															}
															>ul {
																border-radius: 0 0 0px 0px;
															}
															
														}
													}
												}
												
											}
										}
									}
									
								}
							}
						}
						
					}
				}
			}
			
		}
	}
	ul {
		background: $gray-200;
		padding: 0 1rem 0 2.5rem;
		li {
			a {
				color: $gray-600;
				border-bottom: 1px solid $white;
				
			}
			
		}
		ul {
			background: $navyblue;
			li {
				a {
					&:hover {
						background: $gray-300 !important;
					}
				}
			}
			a {
				border-top: solid 1px $jellybean;
			}
		}
		a {
			background: transparent;
			color: $white;
			font-size: 14px;
			line-height: 19px;
			font-weight: 400;
			text-shadow: none;
			padding: 0.5rem 0.5rem 0.5rem 1.5rem;
			&.current {
				background: $jellybean;
				color: $white;
			}
			&.disabled {
				color: $gray-300;
			}
		}
		
	}
}
#top-menu {
	>li {
		>ul {
			>li {
				>a {
					&:not(:only-child) {
						&::after {
							content: '';
							position: absolute;
							top: 38%;
							right: 0;
							width: 0;
							height: 0;
							border-top: 5px solid transparent;
							border-left: 5px solid $gray-900;
							border-bottom: 5px solid transparent;
						}
					}
				}
			}
		}
	}
	&::-webkit-scrollbar {
		width: .5em;
		transition: .5s ease-in-out;
		display: none;
	}
	&:hover {
		&::-webkit-scrollbar {
			display: block;
		}
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
	}
	&::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 0px solid slategrey;
	}
}
li.menu-sub-mobile {
	>a {
		&:not(:only-child) {
			&::after {
				content: '';
				position: absolute;
				top: 38%;
				right: 10px;
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-left: 5px solid $white;
				border-bottom: 5px solid transparent;
			}
		}
	}
	&.active {
		>a {
			&:not(:only-child) {
				&::after {
					transform: rotate(90deg);
				}
			}
		}
		>ul {
			display: block;
		}
	}
	&.in-active {
		>ul {
			display: none;
		}
	}
}

li.menu-mobile {
	>a {
		&:not(:only-child) {
		// 	&::after {
		// 		content: '';
		// 		position: absolute;
		// 		top: 38%;
		// 		right: 10px;
		// 		width: 0;
		// 		height: 0;
		// 		border-top: 5px solid transparent;
		// 		border-left: 5px solid $white;
		// 		border-bottom: 5px solid transparent;
		// 	}
		&::before {
			content: "\f107";
			font-size: 12px;
			font-family: $font-awesome;
			font-weight: 600;
			font-style: normal;
			position: absolute;
			right: 15px;
			top: 9px;
			transition: .4s;
		}
		 }
		
	}
	&.active {
		>a {
			&:not(:only-child) {
				&::before {
					transform: rotate(-90deg);
				}
			}
		}
		>ul {
			display: block;
		}
	}
	&.in-active {
		>ul {
			display: none;
		}
	}
}


@media (min-width: 2500px) {
	.sm-blue {
		ul {
			position: absolute;
			width: 12em;
			border: 1px solid $gray-400;
			padding: 7px 0;
			background: $white;
			border-radius: 0 0 4px 4px !important;
			box-shadow: 0 5px 12px rgba($black, 0.2);
			li {
				float: none;
				border: 0;
			}
			a {
				white-space: normal;
				border: 0 !important;
				padding: 9px 23px;
				background: transparent;
				color: $jellybean;
				border-radius: 0 !important;
				&:hover,&:focus,&:active {
					border: 0 !important;
					padding: 9px 23px;
					background: transparent;
					color: $jellybean;
					border-radius: 0 !important;
					background: $jellybean;
					background-image: linear-gradient(to bottom, $jellybean, $jellybean);
					color: $white;
				}
				
				.sub-arrow {
					top: 50%;
					margin-top: -5px;
					bottom: auto;
					left: auto;
					margin-left: 0;
					right: 10px;
					border-style: dashed dashed dashed solid;
					border-color: transparent transparent transparent $ltblue;
				}
				&.highlighted {
					border: 0 !important;
					padding: 9px 23px;
					background: transparent;
					color: $jellybean;
					border-radius: 0 !important;
					background: $jellybean;
					background-image: linear-gradient(to bottom, $jellybean, $jellybean);
					color: $white;
				}
				&.current {
					background: $jellybean;
				color: $white;
				}
				&.disabled {
					background: $white;
					color: $gray-300;
				}
			}
			ul {
				border-radius: 4px !important;
				background: $white;
			}
			
		}
		li {
			float: left;
		}
		a {
			white-space: nowrap;
			padding: 13px 15px;
			color: $white;
			&:hover,&:focus,&:active {
				padding: 13px 15px;
				color: $white;
				background: $jellybean;
				background-image: linear-gradient(to bottom, $jellybean, $jellybean);
			}
			
			.sub-arrow {
				top: auto;
				margin-top: 0;
				bottom: 2px;
				left: 50%;
				margin-left: -5px;
				right: auto;
				width: 0;
				height: 0;
				border-width: 5px;
				border-style: solid dashed dashed dashed;
				border-color: $ltblue transparent transparent transparent;
				background: transparent;
				border-radius: 0;
				&::before {
					display: none;
				}
			}
			&.highlighted {
				padding: 13px 15px;
				color: $white;
				background: $jellybean;
			}
			&.current {
				background: $jellybean;
				color: $white;
			}
			&.disabled {
				background: $ltblue;
				color: $ltblue;
			}
		}
		.sm-nowrap {
			>li {
				>a {
					white-space: nowrap;
				}
				
					&>:not(ul) {
						a {
							white-space: nowrap;
						}
					}
				
			}
		}
		background: $jellybean;
		background-image: linear-gradient(to bottom, $jellybean, $jellybean);
		border-radius: 8px;
		box-shadow: 0 1px 1px rgba($black, 0.2);
		
		>li {
			&:first-child {
				>a {
					border-radius: 8px 0 0 8px;
				}
				
					&>:not(ul) {
						a {
							border-radius: 8px 0 0 8px;
						}
					}
				
				border-left: 0;
			}
			&:last-child {
				>a {
					border-radius: 0 8px 8px 0 !important;
				}
				
					&>:not(ul) {
						a {
							border-radius: 0 8px 8px 0 !important;
						}
					
				}
			}
			border-left: 1px solid $jellybean;
		}
		.scroll-up {
			position: absolute;
			display: none;
			visibility: hidden;
			overflow: hidden;
			background: $white;
			height: 20px;
		}
		.scroll-down {
			position: absolute;
			display: none;
			visibility: hidden;
			overflow: hidden;
			background: $white;
			height: 20px;
		}
		.scroll-up-arrow {
			position: absolute;
			top: -2px;
			left: 50%;
			margin-left: -8px;
			width: 0;
			height: 0;
			overflow: hidden;
			border-width: 8px;
			border-style: dashed dashed solid dashed;
			
		}
		.scroll-down-arrow {
			position: absolute;
			top: -2px;
			left: 50%;
			margin-left: -8px;
			width: 0;
			height: 0;
			overflow: hidden;
			border-width: 8px;
			border-style: dashed dashed solid dashed;
			
			top: 6px;
			border-style: solid dashed dashed dashed;
			border-color: $jellybean transparent transparent transparent;
		}
	}
	.sm-blue.sm-rtl {
		li {
			float: right;
		}
		ul {
			li {
				float: none;
			}
			a {
				.sub-arrow {
					right: auto;
					left: 10px;
					border-style: dashed solid dashed dashed;
				}	
			}
		}
		>li {
			&:first-child {
				>a {
					border-radius: 0 8px 8px 0;
				}
				
					&>:not(ul) {
						a {
							border-radius: 0 8px 8px 0;
						}
					
				}
				border-left: 1px solid $jellybean;
			}
			&:last-child {
				>a {
					border-radius: 8px 0 0 8px !important;
				}
				
					&>:not(ul) {
						a {
							border-radius: 8px 0 0 8px !important;
						}
					
				}
				border-left: 0;
			}
		}
	}
	.sm-blue.sm-vertical {
		li {
			float: none;
		}
		a {
			white-space: normal;
			padding: 9px 23px;
			.sub-arrow {
				top: 50%;
				margin-top: -5px;
				bottom: auto;
				left: auto;
				margin-left: 0;
				right: 10px;
				border-style: dashed dashed dashed solid;
				border-color: transparent transparent transparent $ltblue;
			}
		}
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
		>li {
			&:last-child {
				>a {
					border-radius: 0 0 0px 0px !important;
				}
				
					&>:not(ul) {
						a {
							border-radius: 0 0 0px 0px !important;
						}
					
				}
			}
			border-left: 0 !important;
		}
		ul {
			border-radius: 4px !important;
			a {
				padding: 9px 23px;
			}
		}
	}
	.sm-blue.sm-rtl.sm-vertical {
		a {
			.sub-arrow {
				right: auto;
				left: 10px;
				border-style: dashed solid dashed dashed;
				
		}
	}
}
	}
// @media (max-width: 1080px) {
// 	ul#main-menu {
// 		>li {
// 			&:last-child {
// 				padding-bottom: 70px !important;
// 			}
// 		}
// 	}
// }

